import React, { useContext, useState } from "react"
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from "@mui/icons-material/Launch";
import Markdown from 'react-markdown';
import { getAlbumEmbed } from '../../util/embed';
import { IAlbum } from "../../@types/album";
import { IPlaylistItem } from "../../@types/playlistItem";
import { apiGetPlaylistItemsByAlbumId } from "../../services/PlaylistItemService";
import { Rating } from "@mui/material";
import { UserContext } from "../../contexts/UserContext";
import { UserContextType } from "../../@types/user";
import { useForceUpdate } from "../../hooks/ForceUpdate";

type Props = {
    token: string,
    album: IAlbum,
    editButtonCallback: Function,
    deleteButtonCallback: Function,
    addToPlaylistCallback: Function | null,
    onItemOpenCallback: Function | null,
}

export default function AlbumItem({token, album, editButtonCallback, deleteButtonCallback, addToPlaylistCallback, onItemOpenCallback}: Props) {
    const forceUpdate = useForceUpdate();
    const {userProfile} = useContext(UserContext) as UserContextType;
    const [comments, setComments] = useState<IPlaylistItem[]>([]);
    const [expanded, setExpanded] = useState(false);

    function handleClickEdit(id: number) {
        editButtonCallback(id);
    }

    function handleClickDelete(id: number) {
        deleteButtonCallback(id);
    }

    function handleClickAdd(id: number) {
        if (addToPlaylistCallback !== null) {
            addToPlaylistCallback(id);
        }
    }

    function onToggleItem(e: any) {
        if (e.target.open && onItemOpenCallback) {
            onItemOpenCallback(e.target);
        }
    }

    const loadComments = async (id: number) => {
        let datas = await apiGetPlaylistItemsByAlbumId(id);

        if (datas) {
            setExpanded(true);
            setComments(datas);
        } else {
            setComments([]);
        }
    }

    const unloadComments = () => {
        setExpanded(false);
        setComments([]);
    }

    const actionButtonFontSize = "small"
    const actionButton = function () {
        const editButton = (token !== null && userProfile && userProfile.is_admin ?
            <button title='Edit album' className="action-button" onClick={() => handleClickEdit(album.id)}><EditIcon
                fontSize={actionButtonFontSize}/></button> : "");
        const deleteButton = (token !== null && userProfile && userProfile.is_admin ?
            <button title='Delete album' className="action-button" onClick={() => handleClickDelete(album.id)}><DeleteIcon
                fontSize={actionButtonFontSize}/></button> : "");
        const addAlbumToPlaylistButton = (!album.inConnectedUserPlaylist && token !== null && token !== "" ?
            <button title='Add album to playlist' className="action-button" onClick={()=> handleClickAdd(album.id)}><AddIcon 
                fontSize={actionButtonFontSize}/></button> : "");

        // For now, we limit edit and deletion of album to the admin
        return (
            <p className="item-buttons-right">
                {addAlbumToPlaylistButton}
                {editButton}
                {deleteButton}
            </p>
        )
    }

    return (
        <>
            <details id={`album-${album.id}`} onToggle={onToggleItem}>
                <summary>
                    <span>{album?.artist} - {album?.title} <span className="text-no-bold font-small">({album?.genre.join(', ')})</span></span><br/>
                        <span className="text-no-bold font-small">Average note: 
                        {album.average > -1 ? <Rating name="average" precision={0.5} value={album?.average} size="small" readOnly/> : " N/A"}</span>
                        <a href={album.url} target="_blank" rel="noreferrer"><button className='inline-button'><LaunchIcon fontSize='small'/></button></a>
                        <br/>
                </summary>
                <div className="item-content">
                    <hr/>

                    {getAlbumEmbed(album.id, album, forceUpdate)}

                    {comments.filter((item) => item.listened).map((item) => 
                        <div className="playlist-item-album" key={item.id}>
                            Listened by {item.user.name}<br/>
                            <span className="font-small">{new Date(item.listeningDate)?.toLocaleDateString()} at {new Date(item.listeningDate)?.toLocaleTimeString()}</span><br/>
                            <Rating name="note" value={item.note} size="small" readOnly/><br/>
                            <Markdown>{item.comment}</Markdown>
                        </div>
                    )}

                    {album.average > -1 ?
                        !expanded ? 
                            <button className="link-button" onClick={() => loadComments(album.id)}>See more</button> : 
                            <button className="link-button" onClick={unloadComments}>See less</button>
                        : ""
                    }
                    {actionButton()}
                </div>
            </details>
        </>
    )
}