import React, { useContext, useEffect, useState } from 'react';
import {toast} from 'react-toastify';
import { UserContext } from '../contexts/UserContext';
import { UserContextType } from '../@types/user';
import { apiGetConnectedDevices, apiPatchUserProfile, apiRevokeAuthToken, apiUpdateUserPassword } from '../services/UserService';

type Props = {
    token: string,
};

export default function UserProfilePage({token}: Props) {
    document.title = "Profile | "+process.env.REACT_APP_TITLE!;
    const bookmarkletLink = `javascript:( function(){ var url = location.href; window.open( '${window.location.origin}/my-list/add?url=' + encodeURIComponent(url), '_blank', 'menubar=no,height=800,width=600,toolbar=no,scrollbars=yes,status=no,dialog=1' ); } )();`;

    const ua = navigator.userAgent;
    const { userProfile, updateUserProfile } = useContext(UserContext) as UserContextType;
    const [name, setName] = useState(userProfile?.name);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [connectedDevices, setConnectedDevices] = useState([]);

    useEffect(() => {
        if (userProfile) {
            apiGetConnectedDevices(userProfile.token).then((json) => {
                setConnectedDevices(json.data)
            })
        };
    }, [userProfile])

    const saveProfile = async () => {
        const response = await apiPatchUserProfile(token, name as string);
        if (response.status === 'error') {
            toast.error(response.message);
        } else {
            if (response.errors) {
                let message = "";
                for (let key in response.errors) {
                    message += response.errors[key]+"\n";
                }
                toast.error(message)
            } else {
                toast.success("Profile updated");
                updateUserProfile(response.data);
            }
        }
    }

    const changePassword = async() => {
        if (userProfile) {
            const response = await apiUpdateUserPassword(token, userProfile.email, currentPassword, newPassword, newPasswordConfirmation);
            if (response.status === 'error') {
                toast.error(response.message);
            } else {
                if (response.errors) {
                    let message = "";
                    for (let key in response.errors) {
                        message += response.error[key]+"\n";
                    }
                    toast.error(message);
                } else {
                    toast.success("Password updated");
                    setCurrentPassword("");
                    setNewPassword("");
                    setNewPasswordConfirmation("");
                }
            }
        }
    }

    const revokeToken = (device: string) => {
        apiRevokeAuthToken(token, device).then((response) => {
            if (response.status === 200) {
                setConnectedDevices(connectedDevices.filter((d) => d !== device))
            }
        });
    }

    const bookmarkletHtml = `<a class="no-decoration full-width" href="${bookmarkletLink}">
        <span class="button-style full-width">Add to playlist</span>
    </a>`;

    return (
        <main>
            <h2 className='text-center'>Profile</h2>
            <div className='align-field'>
                <p>
                    <label htmlFor='name'>User name: </label>
                    <input id='name' value={name} onChange={(e) => setName(e.target.value)}></input>
                </p>
            </div>
            <div className='text-center'>
                <button onClick={saveProfile}>Save change</button>
            </div>
            <hr style={{width: "75%", textAlign: "center"}}/>
            <div className='align-field'>
                <p>
                    <label htmlFor='oldpassword'>Old password: </label>
                    <input id='oldpassword' name='oldpassword' type='password' value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                </p>
                <p>
                    <label htmlFor='newpassword'>New password: </label>
                    <input id='newpassword' name='newpassword' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </p>
                <p>
                    <label htmlFor='newpassword_confirmation'>Confirm new password: </label>
                    <input id='newpassword_confirmation' name='newpassword_confirmation' type='password' value={newPasswordConfirmation} onChange={(e) => setNewPasswordConfirmation(e.target.value)} />
                </p>
            </div>
            <div className='text-center'>
                <button onClick={changePassword}>Update password</button>
            </div>
            <div>
                <h2 className='text-center'>Bookmarklets</h2>
                <p>Drag this button to your bookmarks or right-click it and "Bookmark This Link", then click on the bookmarklet in any bandcamp album you want to share</p>
                <div className='full-width text-center' dangerouslySetInnerHTML={{__html: bookmarkletHtml}}>
                    
                </div>
            </div>
            <div>
                <h2 className='text-center'>Connected devices</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Device</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {connectedDevices.map(device => 
                            <tr key={device}>
                                <td>{device} {device === ua ? <span className='text-bold'>(current)</span> : ''}</td>
                                <td><button disabled={device === ua} title={device === ua ? 'To revoke this token, please logout' : 'Revoke this device token'} onClick={() => revokeToken(device)}>x</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </main>
    )
}