import React, { useEffect, useState } from 'react';
import {toast} from 'react-toastify';
import { apiRegister, apiVerifyEmail, apiGetConfiguration } from '../services/SecurityService';
import { useNavigate } from 'react-router-dom';
import { captchaRender } from '../util/captchaRender.js'

export default function RegisterPage({verify=false, token=""}) {
    document.title = "Register | "+process.env.REACT_APP_TITLE!;

    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [registered, setRegistered] = useState(false);
    const [verified, setVerified] = useState(false);
    const [registrationOnGoing, setRegistrationOnGoing] = useState(false);
    const [acceptTos, setAcceptTos] = useState(false);

    useEffect(() => {
        const checkRegister = async () => {
            const config = await apiGetConfiguration();

            if (config.get("noregistration") === "1") {
                navigate('/');
            }
        }
        checkRegister()
    }, [navigate])

    const verifyEmail = async () => {
        const response = await apiVerifyEmail(token);
        if (response.message === "Verified") {
            setVerified(true);
            toast.success("Email verified");
        }
    }

    const submitForm = async (e: any) => {
        e.preventDefault();
        setRegistrationOnGoing(true);

        if (!acceptTos) {
            toast.error("You have to accept the terms of service");
            setRegistrationOnGoing(false);
            return;
        }

        if (password === passwordConfirmation) {
            const form: HTMLFormElement | null = document.querySelector('form#register-form');
            let formData;
            if (form) {
                formData = new FormData(form);   

                const response = await apiRegister(formData);
                
                if (response.status === 'error') {
                    toast.error(response.message);
                    setRegistrationOnGoing(false);
                } else {
                    if (response.errors) {
                        let message = "";
                        for (let key in response.errors) {
                            message += response.errors[key]+"\n";
                        }
                        toast.error(message)
                        setRegistrationOnGoing(false);
                    } else {
                        toast.success("User created with success");
                        setRegistered(true);
                    }
                }
            }
        } else {
            toast.error("Password don't match");
            setRegistrationOnGoing(false);
        }
    }

    if (verify) {
        verifyEmail()
    }

    document.addEventListener('DOMContentLoaded', captchaRender);

    return (
        <main>
            <div hidden={!verify}>
                <div hidden={verified}>
                    <p>Your account is now verified</p>
                    <p>Enjoy!</p>
                    <p><a href='/'>Home</a></p>
                </div>
                <div hidden={!verified}>
                    <p>Email verification ongoing...</p>
                </div>
            </div>
            <div hidden={!registered || verify}>
                <p>Registration successful.</p>
                <p>You'll be able to log in when your account is approved</p>
                <p><a href='/'>Home</a></p>
            </div>
            <div  hidden={registered || verify}>
                <form id='register-form'>
                    <div className='align-field'>
                        <p>
                            <label htmlFor='username'>Username: </label>
                            <input id='username' name='username' />
                        </p>
                        <p>
                            <label htmlFor='email'>Email: </label>
                            <input id='email' name='email' type='email' />
                        </p>
                        <p>
                            <label htmlFor='password'>Password: </label>
                            <input id='password' name='password' type='password' onChange={(e) => setPassword(e.target.value)} />
                        </p>
                        <p>
                            <label htmlFor='password_confirmation'>Retype password: </label>
                            <input id='password_confirmation' name='password_confirmation' type='password' onChange={(e) => setPasswordConfirmation(e.target.value)} />
                        </p>
                    </div>
                    <input type='checkbox' id='accept-tos' name='accept-tos' onChange={(e) => setAcceptTos(e.target.checked)}/>
                    <label htmlFor='accept-tos'>I accept the <a href='/terms-of-service' target='_blank'>Terms of Service</a> and the <a href='/terms-of-service' target='_blank'>Privacy Policy</a></label>
                    <div className="iconcaptcha-widget" data-theme="light"></div>
                    <p className='text-center'><button disabled={registrationOnGoing} onClick={submitForm}>Sign up</button></p>
                </form>
            </div>
        </main>
    )
}