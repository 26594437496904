import React, { useContext, useEffect, useState } from 'react';
import {toast} from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';
import { IUserAdmin, UserContextType } from '../../@types/user';
import { apiAdminGetUserList, apiAdminStoreConfiguration } from '../../services/AdminService';
import AdminUserList from './AdminUserList';
import { apiGetConfiguration } from '../../services/SecurityService';

export default function AdminComponent() {
    const { userProfile } = useContext(UserContext) as UserContextType;
    const [userList, setUserList] = useState<IUserAdmin[]>([]);
    const [config, setConfig] = useState<Map<string, string> | null>(null);
    const [noRegistration, setNoRegistration] = useState(false);
    const [termsOfService, setTermsOfService] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState("");

    useEffect(() => {
        async function fetchData() {
            if (userProfile) {
                const datas = await apiAdminGetUserList(userProfile.token);
                if (datas) {
                    setUserList(datas);
                }
            }

            apiGetConfiguration().then((data: any) => {
                setConfig(data);
                if (data.get("noregistration") === "1") {
                    setNoRegistration(true);
                }
                setTermsOfService(data.get("termsofservice"));
                setPrivacyPolicy(data.get("privacypolicy"));
            });
        }

        fetchData();
        
    }, [userProfile]);

    const saveConfiguration = async () => {
        if (userProfile) {
            apiAdminStoreConfiguration(userProfile.token, "noregistration", (noRegistration ? "1" : "0")).then((response) => {
                if (response.status === 201) {
                    toast.success("Configuration saved");
                }
            });
        }
    }

    const saveTermsOfService = async () => {
        if (userProfile) {
            apiAdminStoreConfiguration(userProfile.token, "termsofservice", termsOfService).then((response) => {
                if (response.status === 201) {
                    toast.success("Terms of service saved");
                }
            });
        }
    }

    const savePrivacyPolicy = async () => {
        if (userProfile) {
            apiAdminStoreConfiguration(userProfile.token, "privacypolicy", privacyPolicy).then((response) => {
                if (response.status === 201) {
                    toast.success("Privacy policy saved");
                }
            });
        }
    }

    if (config) {
        return (
            <>
                <h1>Administration</h1>
                <h2>Configuration</h2>
                <input type="checkbox" checked={noRegistration} onChange={(e) => setNoRegistration(e.currentTarget.checked)} name='noregistration' />
                <label htmlFor='noregistration'>Disable registration</label>
                <br/>
                <button onClick={saveConfiguration}>Save</button>
    
                <AdminUserList userList={userList} setUserList={setUserList}/>

                <details>
                    <summary>Terms of Service</summary>
                    <p>Edit ToS (use Markdown syntax)</p>
                    <textarea style={{width: "100%", height:"300px"}} onChange={(e) => setTermsOfService(e.currentTarget.value)} value={termsOfService} />
                    <button onClick={saveTermsOfService}>Save</button>
                </details>
                <details>
                    <summary>Privacy Policy</summary>
                    <p>Edit Privacy Policy  (use Markdown syntax)</p>
                    <textarea style={{width: "100%", height:"300px"}} onChange={(e) => setPrivacyPolicy(e.currentTarget.value)} value={privacyPolicy} />
                    <button onClick={savePrivacyPolicy}>Save</button>
                </details>
            </>
        )
    } else {
        return (
            <>
                <h1>Loading ...</h1>
            </>
        )
    }
}