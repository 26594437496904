import React, {useCallback, useEffect, useState} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import SyncIcon from '@mui/icons-material/Sync';
import {Autocomplete, IconButton, TextField, FormControlLabel, Checkbox} from "@mui/material";
import { IAlbum } from "../../@types/album";
import { IBandcampDetail } from "../../@types/bandcampDetails";
import { apiGetBandcampItemDetails, apiGetGenres } from "../../services/AlbumService";

type Props = {
    albumId: number,
    albumInit: IAlbum | null,
    cancelCallback: Function,
    submitCallback: Function,
    token: string,
    addingPlaylistItem: boolean,
}

export default function FormAlbum({albumId, albumInit, cancelCallback, submitCallback, token, addingPlaylistItem=false}: Props) {
    const [open, setOpen] = useState(true);
    const [url, setUrl] = useState(albumId !== 0 && albumInit ? albumInit.url : "");
    const [artist, setArtist] = useState(albumId !== 0 && albumInit ? albumInit.artist : "");
    const [title, setTitle] = useState(albumId !== 0 && albumInit ? albumInit.title : "");
    const [genre, setGenre] = useState(albumId !== 0 && albumInit ? albumInit.genre : []);
    const [listened, setListened] = useState(false);

    const [errorUrl, setErrorUrl] = useState(false);
    const [errorArtist, setErrorArtist] = useState(false);
    const [errorTitle, setErrorTitle] = useState(false);
    const [errorGenre, setErrorGenre] = useState(false);
    const [genreList, setGenreList] = useState<string[]>([]);

    // handle what happens on key press
    const handleKeyPress = useCallback((event: any) => {
        if (event.key === "Enter") {
            let submitButton = document.getElementById("save-button");
            if (submitButton !== null) {
                submitButton.click();
            }
        }
    }, []);

    useEffect(() => {
        apiGetGenres().then((data) => { if (data) {setGenreList(data)}});

        // attach the event listener
        document.addEventListener('keypress', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [handleKeyPress]);

    function handleClose(e: any, reason = "") {
        e.preventDefault()
        if (reason === "backdropClick") {
            return;
        }
        setOpen(false);
        cancelCallback();
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        if (!checkRequiredField()) {
            return
        }

        let album = {
            'url': url,
            'artist': artist,
            'title': title,
            'genre': genre,
        }

        if (albumId !== 0) {
            submitCallback(albumId, album)
        } else {
            submitCallback(album, listened)
        }
    }

    function checkRequiredField() {
        let fieldsOk = true
        setErrorUrl(false)
        setErrorArtist(false)
        setErrorTitle(false)
        setErrorGenre(false)

        fieldsOk = fieldsOk && checkField(url, setErrorUrl, /^(ftp|http|https):\/\/[^ "]+$/)
        fieldsOk = fieldsOk && checkField(artist, setErrorArtist)
        fieldsOk = fieldsOk && checkField(title, setErrorTitle)
        fieldsOk = fieldsOk && checkField(genre, setErrorGenre)

        return fieldsOk
    }

    function checkField(value: any, setError: Function, regex: RegExp | null = null) {
        let fieldOk = true
        if ((value === null || value === "") || (regex !== null && !regex.test(value))) {
            setError(true)
            fieldOk = false
        }

        return fieldOk
    }

    async function prefillField() {
        const details: IBandcampDetail | null = await apiGetBandcampItemDetails(url, token);
        if (details == null) {
            return;
        }

        setArtist(decodeString(details.artist));
        setTitle(decodeString(details.title));

        // Dispatch event to change the input label display
        const artistField = document.getElementById('artist')
        const titleField = document.getElementById('title')
        if (artistField !== null && titleField !== null) {
            artistField.dispatchEvent(new Event('filled'))
            titleField.dispatchEvent(new Event('filled'))
        }
    }

    function decodeString(input: string): string {
        let div = document.createElement('div');
        div.innerHTML = input

        if (div && div.firstChild && div.firstChild.nodeValue) {
            return div.firstChild.nodeValue;
        } else {
            return "";
        }
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{albumId !== 0 ? "Edit album" : "Add album"}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="url"
                    name="url"
                    label={"URL"}
                    sx={{"width": "85%"}}
                    variant="standard"
                    required={true}
                    error={errorUrl}
                    helperText={errorUrl ? "URL is empty or invalid" : ""}
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                />
                <IconButton onClick={prefillField}
                            style={{"verticalAlign": "bottom"}}><SyncIcon
                    fontSize="small"/></IconButton>
                <TextField
                    margin="dense"
                    id="artist"
                    name="artist"
                    label={"Artist"}
                    fullWidth
                    variant="standard"
                    required={true}
                    error={errorArtist}
                    helperText={errorArtist ? "Artist is required" : ""}
                    value={artist}
                    onChange={e => setArtist(e.target.value)}
                />
                <TextField
                    margin="dense"
                    id="title"
                    name="title"
                    label={"Title"}
                    fullWidth
                    variant="standard"
                    required={true}
                    error={errorTitle}
                    helperText={errorTitle ? "Title is required" : ""}
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
                <Autocomplete
                    id="genre"
                    multiple
                    fullWidth
                    options={genreList}
                    defaultValue={[...genre]}
                    freeSolo
                    onChange={(e, value) => setGenre(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Genre"
                            error={errorGenre}
                            helperText={errorGenre ? "Genre is required" : ""}
                        />
                    )}
                />
                {
                    addingPlaylistItem ?
                    <p><FormControlLabel
                    control={<Checkbox
                        id="listened"
                        name="listened"
                        checked={listened}
                        onChange={e => setListened(e.target.checked)}
                    />} label="Listened"/></p>:
                    ""}
            </DialogContent>
            <DialogActions>
                <button className="action-button" onClick={handleClose}>Cancel</button>
                <button className="action-button" id="save-button"
                        onClick={handleSubmit}>{albumId !== 0 ? "Edit" : "Add"}</button>
            </DialogActions>
        </Dialog>
)
}