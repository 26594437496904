import './App.css';
import {useState, useEffect} from "react";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomePage from "./pages/HomePage.tsx";
import RegisterPage from "./pages/RegisterPage.tsx";
import PlaylistPage from "./pages/PlaylistPage.tsx";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PlaylistItemPage from './pages/PlaylistItemPage.tsx';
import logo from './logo.png'
import SearchPage from './pages/SearchPage.tsx';
import UserProfilePage from './pages/UserProfilePage.tsx';
import AdminPage from './pages/AdminPage.tsx';
import TermsOfServicePage from './legal/TermsOfServicePage.tsx';
import PrivacyPolicyPage from './legal/PrivacyPolicyPage.tsx';
import UserMenu from './components/menus/UserMenu.tsx';
import LegalDocument from './legal/LegalDocument.tsx';
import UserProvider from './contexts/UserContext.tsx';
import LocalStoragePage from './pages/LocalStoragePage';
import PlaylistAddPage from './pages/PlaylistAddPage';

function App() {
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [logged, setLogged] = useState(token !== null);
    const [showTopButton, setShowTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.scrollY > 250) {
            setShowTopButton(true);
          } else {
            setShowTopButton(false);
          }
        });
      }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="App">
            <UserProvider>
                <ToastContainer
                    position='top-left'
                />
                <header>
                    <h1><a href="/"><img className="header-logo" alt="logo of Playlist Share application"
                                        src={logo}/></a> {process.env.REACT_APP_TITLE}</h1>
                    <nav>
                        <a id='home-link' href="/">Home</a>
                        {
                            logged ?
                            <a id='my-list-link' href="/my-list">My List</a> : ""
                        }
                        <a id='search-link' href="/search">Search</a>
                    </nav>
                    
                    <UserMenu 
                        token={token} 
                        setToken={setToken} 
                        logged={logged} 
                        setLogged={setLogged}
                    />
                </header>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<HomePage token={token}/>}/>
                        <Route path="/page/:page" element={<HomePage token={token}/>}/>
                        <Route path="/my-list" element={<PlaylistPage token={token}/>}/>
                        <Route path="/my-list/page/:page" element={<PlaylistPage token={token}/>}/>
                        <Route path="/my-list/add" element={<PlaylistAddPage />}/>
                        {/* <Route path="/album/:id" element={<AlbumPage token={token}/>}/> */}
                        <Route path="/user-list/:userId" element={<PlaylistPage token={token}/>}/>
                        <Route path="/user-list/:userId/page/:page" element={<PlaylistPage token={token}/>}/>
                        <Route path="/playlist-item/:id" element={<PlaylistItemPage token={token}/>}/>
                        <Route path="/search" element={<SearchPage token={token}/>}/>
                        <Route path="/search/page/:page" element={<SearchPage token={token}/>}/>
                        <Route path="/register" element={<RegisterPage />}/>
                        <Route path="/profile" element={<UserProfilePage token={token} />}/>
                        <Route path="/admin" element={<AdminPage />}/>
                        <Route path="/verify-email/:id/:hash" element={<RegisterPage verify={true} token={token}/>} />
                        <Route path="/terms-of-service" element={<TermsOfServicePage />}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />}/>
                        <Route path="/browser-storage" element={<LocalStoragePage />}/>
                    </Routes>
                </BrowserRouter>
                <footer>
                    <span className="copyleft">&copy;</span> blchrd - {(new Date().getFullYear())}<br/>
                    <LegalDocument />
                    <span className="font-small"><a href="/browser-storage">Local storage usage</a></span><br/>
                    <span className="font-small"><a href="https://framagit.org/blchrd/playlistshare-docker">Source code of this website</a></span>
                </footer>
            </UserProvider>

            {showTopButton && (
                <button title="Scroll to top" className='scroll-to-top-button' onClick={scrollToTop}>
                    <ArrowDropUpIcon />
                </button>
            )}
            
        </div>
    );
}

export default App;
