import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContextType } from '../@types/user';
import { UserContext } from '../contexts/UserContext';
import { apiGetUserRole } from '../services/UserService';
import AdminComponent from '../components/admin/AdminComponent';

export default function AdminPage() {
    document.title = "Administration | "+process.env.REACT_APP_TITLE!;

    const [isAdmin, setIsAdmin] = useState(false);
    const { userProfile } = useContext(UserContext) as UserContextType;
    const navigate = useNavigate();
    const token = userProfile?.token;
    
    useEffect(() => {
        if (userProfile === null || token === null) {
            navigate('/');
            return;
        }
        apiGetUserRole(token!).then((data) => {
            if (data && data.is_admin) {   
                setIsAdmin(true);
            } else {
                navigate('/');
            }
        })
    }, [isAdmin, token, navigate, userProfile])

    return (
        <main>
            {isAdmin ? <AdminComponent /> : <h1>Loading...</h1>}
        </main>
    )
}