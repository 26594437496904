import React from "react"
import {toast} from "react-toastify";
import { IAlbum } from "../../@types/album";
import { apiAddAlbum, apiUpdateAlbum } from "../../services/AlbumService";
import FormAlbum from "./FormAlbum";

type Props = {
    token: string,
    setRefresh: Function,
    isAdding: boolean,
    isEditing: boolean,
    setIsAdding: Function,
    setIsEditing: Function,
    albumInEdition: IAlbum | null,
    setAlbumInEdition: Function,
    callback: Function | null,
    addingPlaylistItem: boolean
}

export default function AlbumEdition({
                                         token,
                                         setRefresh,
                                         isAdding,
                                         isEditing,
                                         setIsAdding,
                                         setIsEditing,
                                         albumInEdition,
                                         setAlbumInEdition,
                                         callback = null,
                                         addingPlaylistItem = false
                                     }: Props) {

    function handleCancelClick() {
        setIsEditing(false)
        setIsAdding(false)

        if (callback) {
            callback();
        }
    }

    async function handleAlbumAdd(album: IAlbum, listened=false) {
        setIsAdding(false)

        let albumAdded = await apiAddAlbum(album, token);
        toast.success("Album added")
        if (callback === null) {
            setRefresh(true);
        } else {
            callback(albumAdded.id, listened);
        }
    }

    async function handleAlbumUpdate(albumId: number, album: IAlbum) {
        setIsEditing(false)
        setAlbumInEdition({})

        let albumUpdated = await apiUpdateAlbum(albumId, album, token)
        toast.success("Album updated successfully")
        if (callback === null) {
            setRefresh(true);
        } else {
            callback(albumUpdated.id);
        }
    }

    return (
        <>
            {isAdding ? 
            <FormAlbum
                cancelCallback={handleCancelClick}
                submitCallback={handleAlbumAdd}
                albumId={0}
                albumInit={null}
                token={token}
                addingPlaylistItem={addingPlaylistItem}
            /> : (isEditing && albumInEdition ? 
                <FormAlbum
                    cancelCallback={handleCancelClick}
                    submitCallback={handleAlbumUpdate}
                    albumId={albumInEdition.id}
                    albumInit={albumInEdition}
                    token={token}
                    addingPlaylistItem={false}
                /> : "")}
        </>
    )
}