import { IAlbum, IAlbumPaginatedCollection, IAlbumPayload } from "../@types/album";
import { IBandcampDetail } from "../@types/bandcampDetails";

const apiUrl = process.env.REACT_APP_API_URL;
const maxItemPerPage = (process.env.REACT_APP_MAX_ITEM_PER_PAGE !== undefined ? process.env.REACT_APP_MAX_ITEM_PER_PAGE : 5);

export const apiGetAlbums = async (filter: string, token: string, page: number, forcePageLimit: number = 0): Promise<IAlbumPaginatedCollection | null> => {
    const urlParams = window.location.search.substring(1)
    const apiParams = filter + '&' + urlParams
    const pageLimit = forcePageLimit === 0 ? maxItemPerPage : forcePageLimit;

    let url
    if (token !== null && token !== "") {
        url = apiUrl + '/albums?page=' + page + '&limit=' + pageLimit + apiParams
    } else {
        url = apiUrl + '/public/albums?page=' + page + '&limit=' + pageLimit + apiParams
    }
    const response = await (fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    if (response.status === 401) {
        return null;
    }

    let jsonResponse = await response.json();
    if (jsonResponse === false) {
        return null;
    }

    const albums = jsonResponse.data
    const links = jsonResponse.links
    const meta = jsonResponse.meta

    if (albums.length > 0) {
        return {
            "albums": albums,
            "links": links,
            "meta": meta,
        }
    } else {
        return {
            "albums": albums,
            "links": [],
            "meta": [],
        }
    }
}

export const apiGetOneAlbumById = async (id: number, token: string): Promise<IAlbum | null> => {
    let url
    if (token !== null) {
        url = apiUrl + '/albums/' + id
    } else {
        url = apiUrl + '/public/albums/' + id
    }
    const response = await (fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    if (response.status === 401 || response.status === 404) {
        return null;
    }

    let jsonResponse = await response.json();
    if (jsonResponse === false) {
        return null;
    }

    return jsonResponse.data
}

export const apiAddAlbum = async (album: IAlbumPayload, token: string): Promise<IAlbum> => {
    const response = await fetch(apiUrl + '/albums', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(album)
    })

    const json = await response.json()
    return json.data
}

export const apiUpdateAlbum = async (albumId: number, album: IAlbumPayload, token: string): Promise<IAlbum> => {
    const response = await fetch(apiUrl + `/albums/${albumId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(album)
    })
    const json = await response.json()
    return json.data
}

export const apiDeleteAlbum = async (albumId: number, token: string): Promise<Response> => {
    return await fetch(apiUrl + `/albums/${albumId}`, {
        method: 'DELETE',
        headers: {
            'accept': '*/*',
            'Authorization': `Bearer ${token}`
        }
    })
}

export const apiGetGenres = async (): Promise<string[] | null> => {
    const response = await (fetch(apiUrl + '/public/genres', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }));

    if (response.status === 401 || response.status === 404) {
        return null;
    }

    let jsonResponse = await response.json();
    if (jsonResponse === false) {
        return null;
    }

    return jsonResponse.data;
}

export const apiGetBandcampItemDetails = async (url: string, token: string): Promise<IBandcampDetail | null> => {
    const response = await (fetch(apiUrl + '/bc-details?url=' + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }));

    if (response.status === 401 || response.status === 404) {
        return null;
    }

    let jsonResponse = await response.json();
    if (jsonResponse === false) {
        return null;
    }

    return jsonResponse.data;
}
