import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { IUserAdmin, IUserAdminPayload } from '../../@types/user';

type Props = {
    user: IUserAdmin,
    open: boolean, 
    setOpen: Function,
    cancelButtonCallback: Function,
    submitButtonCallback: Function,
}

export default function AdminFormUser({user, open, setOpen, cancelButtonCallback, submitButtonCallback}: Props) {
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [verified, setVerified] = useState(user.email_verified_at !== null)

    const handleClose = () => {
        cancelButtonCallback();
        setOpen(false);
    }

    const handleSubmit = () => {
        let userPayload: IUserAdminPayload = {
            id: user.id,
            name: name,
            email: email,
            verified: verified,
        };
        submitButtonCallback(userPayload);
        setOpen(false)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit users</DialogTitle>
            <DialogContent>
                <TextField 
                    margin="dense"
                    id="name"
                    name="name"
                    label={"Username"}
                    fullWidth
                    variant="standard"
                    required={true}
                    defaultValue={name}
                    onChange={e => setName(e.target.value)}
                />
                <TextField 
                    margin="dense"
                    id="email"
                    name="email"
                    label={"E-mail"}
                    fullWidth
                    variant="standard"
                    required={true}
                    type='email'
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox
                        id="verified"
                        name="verified"
                        checked={verified}
                        onChange={e => setVerified(e.target.checked)}
                    />} label="Verified"/>
            </DialogContent>
            <DialogActions>
                <button className="action-button" onClick={handleClose}>Cancel</button>
                <button className="action-button" id="save-button"
                        onClick={handleSubmit}>Save</button>
            </DialogActions>
        </Dialog>
    )
}