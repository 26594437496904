import { IAlbum } from "../@types/album"

export function getAlbumEmbed(id: number, album: IAlbum, callback: Function|null = null) {
    const embedConsent = localStorage.getItem('embed_consent');
    if (embedConsent !== null && embedConsent === "1") {
        let embedCode = getArchiveEmbedCode(id, album);
        if (embedCode !== "") return embedCode;
    
        embedCode = getYoutubeEmbedCode(id, album);
        if (embedCode !== "") return embedCode;
    
        embedCode = getBandcampEmbedCode(id, album);
        if (embedCode !== "") return embedCode;

        embedCode = getMixcloudEmbedCode(id, album);
        if (embedCode !== "") return embedCode;
    } else {
        return (
            <div className="consent font-small">
                <p>
                    This embed could track you internet activity.<br/>
                    If you enable it, {process.env.REACT_APP_TITLE} can't guarantee you won't be tracked by the embed provider.
                </p>
                <p>
                    Do you want to enable the third-party embed?<br/>
                    You can reset this setting by clicking the "Local storage usage" link on the footer
                </p>
                <p>
                    <button className="link-button font-small" onClick={() => enableEmbed(callback)}>Enable</button>
                </p>
            </div>
        )
    }  
}

const enableEmbed = (callback: Function|null) => {
    localStorage.setItem("embed_consent", "1")
    if (callback) {
        callback();
    }
}

const getMixcloudEmbedCode = (id: number, album: IAlbum) => {
    const mixcloudUrlRegex = /^(https?:\/\/)?(www\.)?mixcloud\.com\/.+$/
    if (mixcloudUrlRegex.test(album.url)) {
        let url = album.url.replace("https://www.mixcloud.com", "");

        return (
            <iframe
                title={album.artist + album.title}
                width="100%"
                height="120"
                src={"https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed="+encodeURIComponent(url)}
                frameBorder="0"
            ></iframe>
        )
    }

    return "";
}

const getArchiveEmbedCode = (id: number, album: IAlbum) => {
    const archiveUrlRegex = /^(https?:\/\/)?(www\.)?archive\.org\/details\/.+$/
    if (archiveUrlRegex.test(album.url)) {
        return (
            <iframe 
                title={album.artist + album.title}
                key={id}
                src={album.url.replace("details", "embed")}
                width="100%"
                height="30"
                frameBorder={0}
                allowFullScreen
            ></iframe>
        )
    }

    return ""
}

const getBandcampEmbedCode = (id: number, album: IAlbum) => {
    const style = getComputedStyle(document.body)
    const colorLink = style.getPropertyValue('--accent').replace("#", "")
    const colorBackground = style.getPropertyValue('--bg').replace("#", "")
    const embedUrl = 'https://bandcamp.com/EmbeddedPlayer/' + album.bandcampItemType + '=' + album.bandcampItemId + '/size=large/bgcol=' + colorBackground + '/linkcol=' + colorLink + '/artwork=small/tracklist=false/transparent=true/'

    if (album.bandcampItemId !== null && album.bandcampItemId !== "" && album.bandcampItemType !== null) {
        return (
            <p className="text-center">
                <iframe
                    title={album.artist + album.title}
                    key={id}
                    style={{border: 0, width: 100 + '%', height: 120 + 'px'}}
                    src={embedUrl}
                    seamless>
                    <a href={album.url}>{album.artist} - {album.title}</a>
                </iframe>
            </p>
        );
    }

    return ""
}

const getYoutubeEmbedCode = (id: number, album: IAlbum) => {
    const url = new URL(album.url)
    const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/
    if (youtubeUrlRegex.test(album.url)) {
        const videoId = url.searchParams.get("v")
        const embedUrl = "https://invidious.fdn.fr/embed/" + videoId
        return (
            <p className="text-center">
                <iframe
                    key={id}
                    width="100%"
                    height="315"
                    src={embedUrl}
                    frameBorder="0"
                    title="YouTube embed video"
                    allowFullScreen>
                </iframe>
            </p>
        )
    }

    return ""
}