import React, {useCallback, useEffect, useState} from "react"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {TextField} from "@mui/material";

type Props = {
    handleLogin: Function,
}

export default function FormLogin({handleLogin}: Props) {
    const [open, setOpen] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    // handle what happens on key press
    const handleKeyPress = useCallback((event: any) => {
        if (event.key === "Enter") {
            let loginButton = document.getElementById("login-button")
            if (loginButton !== null) {
                loginButton.click();
            }
        }
    }, []);

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keypress', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [handleKeyPress]);

    function handleClose(e: any) {
        e.preventDefault()
        setOpen(false)
    }

    function handleSubmit(e: any) {
        e.preventDefault()
        setOpen(false)
        handleLogin(username, password)
    }

    return (
        <>
            <li><button className="link-button" onClick={() => setOpen(true)}>Login</button></li>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        name="username"
                        label={"Login"}
                        fullWidth
                        variant="standard"
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        name="password"
                        label={"Password"}
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={e => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <button className="action-button" onClick={handleClose}>Cancel</button>
                    <button id="login-button" className="action-button" onClick={handleSubmit}>Login</button>
                </DialogActions>
            </Dialog>
        </>
    )
}