import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';

import {toast} from 'react-toastify';
import { apiAuthentication, apiGetConfiguration, apiLogout } from '../../services/SecurityService';
import { UserContextType } from '../../@types/user';
import { UserContext } from '../../contexts/UserContext';
import { apiGetUserProfile } from '../../services/UserService';
import FormLogin from '../forms/FormLogin';

type Props = {
    token: string,
    setToken: Function,
    logged: boolean,
    setLogged: Function,
}

export default function UserMenu({token, setToken, logged, setLogged}: Props) {
    const { userProfile, updateUserProfile, removeUserProfile } = useContext(UserContext) as UserContextType
    const [menuOpen, setMenuOpen] = useState(false);
    const [registrationMenu, setRegistrationMenu] = useState<any>("");

    useEffect(() => {
        const checkRegister = async () => {
            const config = await apiGetConfiguration();
            
            if (config.get("noregistration") === "1") {
                setRegistrationMenu("");
            } else {
                setRegistrationMenu(<li><a className='font-1rem' href="/register">Register</a></li>);
            }
        }
        checkRegister()
    }, [])

    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    }

    async function handleLogin(username: string, password: string) {
        const jsonResponse = await apiAuthentication(username, password)

        if (jsonResponse.hasOwnProperty('access_token')) {
            const jsonToken = jsonResponse.access_token;
            setToken(jsonToken);
            setLogged(true)

            let userProfile = await apiGetUserProfile(jsonToken);
            userProfile.token = jsonToken;
            updateUserProfile(userProfile);

            toast.success("Successfully logged in")
        } else {
            if (jsonResponse.hasOwnProperty('message')) {
                toast.error(jsonResponse.message);
            } else {
                toast.error("Invalid credentials");
            }
        }

        setMenuOpen(false)
    }

    function handleLogout() {
        apiLogout(token)
        setLogged(false)
        setToken(null)
        setMenuOpen(false)
        window.location.href = '/';

        removeUserProfile();
        toast.success("Successfully logged out")
    }

    return (
        <div className='user-button-container'>
            {/* Make user a dropdown menu with Login/Register or Logout */}
            <button className='user-button' onClick={toggleMenuOpen}><PersonIcon /></button>

            <div className='user-menu' hidden={!menuOpen}>
                {userProfile ? <p>{userProfile.name}</p> : ""}
                <ul>
                    {logged ? <li><a className='font-1rem' href='/profile'>Profile</a></li> : ""}
                    {logged && userProfile && userProfile.is_admin ? <li><a className='font-1rem' href='/admin'>Admin</a></li> : ""}
                    {!logged ?
                        <FormLogin handleLogin={handleLogin} /> :
                        (<li><button className="link-button" onClick={handleLogout}>Logout</button></li>)
                    }
                    {!logged ? registrationMenu : ""}
                </ul>
            </div>
        </div>
    );
}