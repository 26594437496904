
export function captchaRender() {
    IconCaptcha.init('.iconcaptcha-widget', { // eslint-disable-line
        general: {
            endpoint: `${process.env.REACT_APP_API_URL}/captcha-request`, // required, change the path accordingly.
            fontFamily: 'inherit',
            credits: 'show',
        },
        security: {
            interactionDelay: 1500,
            hoverProtection: true,
            displayInitialMessage: true,
            initializationDelay: 500,
            incorrectSelectionResetDelay: 3000,
            loadingAnimationDuration: 1000,
        },
        locale: {
            initialization: {
                verify: 'Verify that you are human.',
                loading: 'Loading challenge...',
            },
            header: 'Select the image displayed the <u>least</u> amount of times',
            correct: 'Verification complete.',
            incorrect: {
                title: 'Uh oh.',
                subtitle: "You've selected the wrong image.",
            },
            timeout: {
                title: 'Please wait.',
                subtitle: 'You made too many incorrect selections.'
            }
        }
    });
}