const apiUrl = process.env.REACT_APP_API_URL;

export const apiGetConfiguration = async (): Promise<Map<string, string>> => {
    const response = await (fetch(apiUrl+'/configuration', {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    }))

    const json = await response.json();
    let map = new Map<string, string>();

    for (var value in json.data) {
        map.set(value, json.data[value]);
    }
    
    return map;
}

export const apiRegister = async (registerForm: FormData) => {
    const response = await (fetch(apiUrl+'/register', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
        },
        body: registerForm
    }));
    console.table(registerForm);

    const json = await response.json();
    if (json.access_token) {
        localStorage.setItem('token', json.access_token);
    }
    
    return json;
}

export const apiVerifyEmail = async (token: string) => {
    const url = apiUrl + window.location.pathname + window.location.search;

    const response = await (fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    const json = await response.json();
    return json;
}

export const apiAuthentication = async (user: string, password: string) => {
    const ua = navigator.userAgent;
    const response = await (fetch(apiUrl+'/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `email=${user}&password=${password}&device_name=${ua}`
    }));
    const json = await response.json();

    localStorage.setItem('token', json.access_token);
    return json;
}

export const apiLogout = async (token: string) => {
    fetch(apiUrl+'/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    localStorage.removeItem('token');
}
