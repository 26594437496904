import React, { useContext } from 'react';
import {Rating} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'
import LockIcon from '@mui/icons-material/Lock';
import AddIcon from "@mui/icons-material/Add";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckIcon from '@mui/icons-material/Check';
import StarsIcon from '@mui/icons-material/Stars';
import ShareComponent from "../ShareComponent";
import Markdown from 'react-markdown';
import { getAlbumEmbed } from '../../util/embed'
import { IPlaylistItem } from '../../@types/playlistItem';
import { UserContext } from '../../contexts/UserContext';
import { UserContextType } from '../../@types/user';
import { useNavigate } from 'react-router-dom';
import { useForceUpdate } from '../../hooks/ForceUpdate';

type Props = {
    id: number,
    item: IPlaylistItem,
    token: string,
    editItemButtonCallback: Function,
    deleteItemButtonCallback: Function,
    editAlbumButtonCallback: Function | null,
    deleteAlbumButtonCallback: Function | null,
    onItemOpenCallback: Function | null
    addButtonCallback: Function,
    owned: boolean,
}

export default function PlaylistItem({id, item, token, editItemButtonCallback, deleteItemButtonCallback, editAlbumButtonCallback, deleteAlbumButtonCallback, addButtonCallback, onItemOpenCallback, owned=false}: Props) {
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const { userProfile } = useContext(UserContext) as UserContextType;
    const comment = (item.listened || (token !== null && userProfile?.id === item.user.id) ? item.comment : "")
    const note = (item.listened || (token !== null && userProfile?.id === item.user.id) ?
        <Rating sx={{verticalAlign: 'middle'}} name="note" value={item.note} size="small" readOnly={!item.listened && (editAlbumButtonCallback !== null)} onChange={(e, note) => handleChangeNote(item.id, note)}/> : "")
    const lock = (item.isPrivate ? <span title="Private"><LockIcon sx={{verticalAlign: 'middle'}} fontSize="small"/></span> : "")
    const date = new Date(item.listeningDate)

    function handleClickEditAlbum(id: number) {
        if (editAlbumButtonCallback) {
            editAlbumButtonCallback(id);
        }
    }

    function handleClickDeleteAlbum(id: number) {
        if (deleteAlbumButtonCallback) {
            deleteAlbumButtonCallback(id);
        }
    }

    function handleClickEditItem(id: number) {
        editItemButtonCallback(id);
    }

    function handleClickDeleteItem(id: number) {
        deleteItemButtonCallback(id);
    }

    function handleChangeNote(id: number, note: number | null) {
        if (editItemButtonCallback) {
            editItemButtonCallback(id, note);
        }
    }

    function onToggleItem(e: any) {
        if (e.target.open && onItemOpenCallback) {
            onItemOpenCallback(e.target);
        }
    }

    function getRoutePermalink() {
        return "/playlist-item/" + id;
    }

    function addToPlaylist(albumId: number) {
        addButtonCallback(albumId);
    }

    const actionButtonFontSize = "small"
    const actionButton = function () {
        const editAlbumButton = (editAlbumButtonCallback && userProfile && userProfile.is_admin ? 
            <button title='Edit album' className="action-button" onClick={() => handleClickEditAlbum(id)}><EditIcon
                fontSize={actionButtonFontSize}/>
            </button> : "");
        const deleteAlbumButton = (deleteAlbumButtonCallback && userProfile && userProfile.is_admin ? 
            <button title='Delete album' className="action-button" onClick={() => handleClickDeleteAlbum(id)}><DeleteIcon
                fontSize={actionButtonFontSize}/>
            </button> : "");;

        const editItemButton = (owned ?
            <button title='Edit item' className="action-button" onClick={() => handleClickEditItem(id)}><EditIcon
                fontSize={actionButtonFontSize}/>
            </button> : "");
        const deleteItemButton = (owned ?
            <button title='Delete item' className="action-button" onClick={() => handleClickDeleteItem(id)}><DeleteIcon
                fontSize={actionButtonFontSize}/></button> : "");
        const addButton = (!owned && token !== null && token !== "" ? 
            (item.album.inConnectedUserPlaylist ? 
            <button disabled={true} title='Album already in playlist' className='action-button' onClick={() => navigate('/my-list')}><CheckIcon fontSize={actionButtonFontSize}/></button>    :
            <button title='Add album to playlist' className='action-button' onClick={() => addToPlaylist(item.album.id)}><AddIcon fontSize={actionButtonFontSize}/></button>)
            : "");
        return (
            <table className="item-buttons">
                <tbody>
                    <tr>
                        <td className='item-buttons-left'>
                            {editAlbumButton}
                            {deleteAlbumButton}
                        </td>
                        <td className='item-buttons-right'>
                            {getShareComponent()}
                            {addButton}
                            {editItemButton}
                            {deleteItemButton}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    function getShareComponent() {
        const genreHashtags = item.album.genre.map((genre) => '#' + genre.replace(' ', '')).join(' ') + ' #Music'

        const title = item.album.artist + ' - ' + item.album.title
        const text = (token !== null && comment !== "" && comment !== null ? comment + "\n\n" : "") + "Genres: " + item.album.genre.join(', ');
        const fulltext = (token !== null && comment !== "" && comment !== null ? comment + "\n\n" : "") + "#NowPlaying " + title + ' ' + item.album.url + ' ' + genreHashtags

        return (
            <ShareComponent
                className="action-button"
                fulltext={fulltext}
                text={text}
                url={item.album.url}
                title={title}
                permalink={getRoutePermalink()}
            />
        )
    }

    return (
        <>
            <details id={`playlist-item-${item.id}`} onToggle={onToggleItem}>
                <summary>
                    <a href={item.album.url} target="_blank" rel="noreferrer"><button className='inline-button'><LaunchIcon fontSize='small'/></button></a>
                    <span>{item.album.artist} - {item.album.title} <span className="text-no-bold font-small">({item.album.genre.join(', ')})</span></span><br/>
                    {note}&nbsp;&nbsp;&nbsp;<span className='text-no-bold font-small'>by <span className='item-user font-small'><a href={`/user-list/${item.user.id}`}>{item.user.name}</a></span></span>
                    &nbsp;&nbsp;&nbsp;
                    <span
                        className="text-no-bold  font-x-small">{item.listened ? "Listened the " + date.toLocaleDateString() + " at " + date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : "Unlistened"}</span>
                    &nbsp;&nbsp;&nbsp;
                    {item.favourite ? <StarsIcon sx={{verticalAlign: 'middle'}} htmlColor='#faaf00'/> : ""}
                    {lock}
                </summary>
                <div className="item-content">
                    <hr/>
                    <Markdown>{comment}</Markdown>
                    {getAlbumEmbed(item.id, item.album, forceUpdate)}

                    <p><a className='font-small' href={getRoutePermalink()}>Permalink</a></p>
                    {actionButton()}
                </div>
            </details>
        </>
    )
}