import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { UserContextType } from '../@types/user';
import { IPlaylistItem } from '../@types/playlistItem';
import PlaylistItemList from '../components/lists/PlaylistItemList';
import { useParams } from 'react-router-dom';
import { IPaginationDataMeta } from '../@types/paginationData';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { apiGetPlaylistItems } from '../services/PlaylistItemService';
import { Rating } from '@mui/material';
import { apiGetAlbums, apiGetGenres } from '../services/AlbumService';
import AlbumList from '../components/lists/AlbumList';
import { IAlbum } from '../@types/album';

enum SearchType {
    PlaylistItem="Playlist item",
    Album="Album",
}

export default function SearchPage() {
    document.title = "Search | "+process.env.REACT_APP_TITLE!;
    const links = {
        home: document.getElementById('home-link'),
        mylist: document.getElementById('my-list-link'),
        search: document.getElementById('search-link'),
    };
    if (links.home) links.home.classList.remove('current-page');
    if (links.mylist) links.mylist.classList.remove('current-page');
    if (links.search) links.search.classList.add('current-page');
    

    const {page} = useParams()
    const {userProfile} = useContext(UserContext) as UserContextType;
    const [searchType, setSearchType] = useState<SearchType | null>(SearchType.PlaylistItem);
    const [playlistItemList, setPlaylistItemList] = useState<IPlaylistItem[]>([]);
    const [albumList, setAlbumList] = useState<IAlbum[]>([]);
    const [genreList, setGenreList] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(page !== null && page !== undefined && parseInt(page) !== 0 ? parseInt(page) : 1);
    const [paginationData, setPaginationData] = useState<IPaginationDataMeta | null>(null);
    const [advFiltersOn, setAdvFiltersOn] = useState(false);

    const [search, setSearch] = useState("");
    const [favourite, setFavourite] = useState(false);
    const [forConnectedUserUnlistened, setForConnectedUserUnlistened] = useState(false);
    const [forConnectedUserListened, setForConnectedUserListened] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [rating, setRating] = useState<number | null>(null);
    const [selectedGenre, setselectedGenre] = useState("");
    const [genres, setGenres] = useState<string[]>([]);
    const [refresh, setRefresh] = useState(false);

    
    useEffect(
        () => {
            executeSearch();
            apiGetGenres().then((data) => { if (data) {setGenreList(data)}});
        }, 
        // eslint-disable-next-line
        [refresh, currentPage]
    );

    const searchTypeFromString = (s: string): SearchType | null => {
        let searchTemp = search;
        resetSearch();
        setSearch(searchTemp);
        switch (s) {
            case SearchType.PlaylistItem.toString() :
                return SearchType.PlaylistItem;
            case SearchType.Album.toString() :
                return SearchType.Album;
            default:
                return null;
        }
    }

    const resetSearch = () => {
        setSearch("");
        resetDateFilters();
        resetRatingFilter();
        resetGenreFilter();
        setForConnectedUserUnlistened(false);
        setForConnectedUserListened(false);
        setAdvFiltersOn(false);
        setPlaylistItemList([]);
        setAlbumList([]);
        setPaginationData(null);
    }

    const resetDateFilters = () => {
        setStartDate("");
        setEndDate("");
    }

    const resetRatingFilter = () => {
        setRating(null);
    }

    const addGenreInFilters = (genre: string) => {
        setselectedGenre(genre);
        if (genre !== "") {
            setGenres([...genres, genre]);
        }
    }

    const resetGenreFilter = () => {
        setselectedGenre("");
        setGenres([]);
    }

    const buildFiltersArray = (): string[] => {
        setAdvFiltersOn(false);
        let filters = [];
        if (search !== "") {
            filters.push(`search=${search}`);
        }
        if (genres.length > 0) {
            filters.push(`genre=${genres.join(',')}`);
            setAdvFiltersOn(true);
        }

        if (searchType !== null && searchType === SearchType.PlaylistItem) {
            if (startDate !== "") {
                filters.push("start="+startDate.replaceAll('-',''));
                setAdvFiltersOn(true);
            }
            if (endDate !== "") {
                filters.push("end="+endDate.replaceAll('-',''));
                setAdvFiltersOn(true);
            }
            if (rating !== null) {
                filters.push(`rating=${rating}`);
                setAdvFiltersOn(true);
            }
            if (favourite === true) {
                filters.push(`favourite=1`);
                setAdvFiltersOn(true);
            }
        }
        if (forConnectedUserListened) {
            filters.push("listened=1");
        }
        if (forConnectedUserUnlistened) {
            filters.push("listened=0");
        }

        return filters;
    }

    const executeSearch = async () => {
        let filters = buildFiltersArray();
        
        let datas: any;
        if (filters.length > 0) {
            if (searchType !== null && searchType !== SearchType.PlaylistItem) {
                datas = await apiGetAlbums(`&${filters.join('&')}`, (userProfile) ? userProfile.token : "", currentPage)
            } else {
                datas = await apiGetPlaylistItems(`&${filters.join('&')}`, (userProfile) ? userProfile.token : "", currentPage, forConnectedUserListened || forConnectedUserUnlistened);
            }
        } else {
            datas = null;
        }

        if (datas) {
            if (searchType !== null && searchType !== SearchType.PlaylistItem) {
                setAlbumList(datas.albums);
            } else {
                setPlaylistItemList(datas.items);
            }
            setPaginationData(datas.meta);
        } else {
            setPlaylistItemList([]);
            setAlbumList([]);
            setPaginationData(null);
        }
    }

    const enterKeyPressed = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            executeSearch();
        }
    }

    return (
        <main>
            <div className='search-page-main'>
                <input placeholder='Search...' onKeyUp={enterKeyPressed} autoFocus name='search-text' id='search-text' onChange={(e) => setSearch(e.target.value)} value={search}/>
                <select value={searchType?.toString()} onChange={e => setSearchType(searchTypeFromString(e.target.value))}>
                    <option value={SearchType.PlaylistItem}>Playlist item</option>
                    <option value={SearchType.Album}>Album</option>
                </select>
                <button type='submit' onClick={executeSearch}><SearchIcon fontSize='small'/></button>
                <button onClick={resetSearch}><ClearIcon fontSize='small' /></button>
            </div>
            <div className='search-page-advanced'>
                <details>
                    <summary>
                        Advanced search {advFiltersOn ? <FilterAltIcon fontSize='small' /> : ""}
                    </summary>
                    <div>
                        <label htmlFor='search-genre'>Genre: </label>
                        <select
                            id="search-genre"
                            name="search-genre"
                            onChange={e => addGenreInFilters(e.target.value)}
                            value={selectedGenre}
                        >
                            <option value="">None</option>
                            {genreList.map((genre) => <option key={genre} id={genre} value={genre}>{genre}</option>)}
                        </select>
                        <button className='search-filter-button' onClick={resetGenreFilter}><ClearIcon fontSize='small' /></button>
                        <br/>
                        <div className='font-small'>{genres.join(', ')}</div>
                    </div>

                    {searchType !== null && searchType !== SearchType.PlaylistItem ? "" :
                        <div>
                            <div>
                                <label htmlFor='search-rating'>Rating: </label>
                                <Rating name='search-rating' id='search-rating' precision={1} value={rating} onChange={(e, value) => {if(value){setRating(value)}}}/>
                                <button className='search-filter-button' onClick={resetRatingFilter}><ClearIcon fontSize='small' /></button>
                            </div>
                            <div>
                                <label>Dates: </label>
                                <input
                                    className="search-input search-date"
                                    type="date"
                                    id="search-date-start"
                                    name="search-date-start"
                                    value={startDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                                <input
                                    className="search-input search-date"
                                    type="date"
                                    id="search-date-end"
                                    name="search-date-end"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                                <button className='search-filter-button' onClick={resetDateFilters}><ClearIcon fontSize='small' /></button>
                            </div>
                            <div>
                                <input 
                                    type='checkbox'
                                    checked={favourite}
                                    onChange={(e) => {
                                        setFavourite(e.target.checked);
                                    }}
                                    name='search-favourite'
                                    id='search-favourite'
                                />
                                <label htmlFor='search-favourite'>Favourite</label>
                                <br/>
                                <input 
                                    type='checkbox' 
                                    checked={forConnectedUserUnlistened} 
                                    onChange={(e) => {
                                        setForConnectedUserUnlistened(e.target.checked);
                                        setForConnectedUserListened(false);
                                    }} 
                                    name='your-playlist-unlistened' 
                                    id='your-playlist-unlistened'
                                />
                                <label htmlFor='your-playlist-unlistened'>My list - Unlistened</label>
                                &nbsp;&nbsp;&nbsp;
                                <input 
                                    type='checkbox' 
                                    checked={forConnectedUserListened} 
                                    onChange={(e) => {
                                        setForConnectedUserUnlistened(false)
                                        setForConnectedUserListened(e.target.checked)
                                    }} 
                                    name='your-playlist-listened' 
                                    id='your-playlist-listened'/>
                                <label htmlFor='your-playlist-listened'>My list - Listened</label>
                            </div>
                        </div>
                    }
                </details>
            </div>

            {searchType !== null && searchType !== SearchType.PlaylistItem ?
                 <AlbumList 
                    baseUrl='/search'
                    currentPage={currentPage}
                    paginationData={paginationData}
                    albumList={albumList}
                    setCurrentPage={setCurrentPage}
                    setRefresh={setRefresh}
                /> :
                <PlaylistItemList 
                    baseUrl='/search'
                    currentPage={currentPage}
                    paginationData={paginationData}
                    playlistItemList={playlistItemList}
                    setCurrentPage={setCurrentPage}
                    setRefresh={setRefresh}
                    token={userProfile ? userProfile.token : ""}
                />
            }
        </main>
    );
}