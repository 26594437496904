import React from "react"
import {toast} from "react-toastify";
import FormPlaylistItem from "./FormPlaylistItem";
import { IPlaylistItem, IPlaylistItemPayload } from "../../@types/playlistItem";
import { apiUpdatePlaylistItem } from "../../services/PlaylistItemService";

type Props = {
    token: string,
    setRefresh: Function,
    isEditing: boolean,
    setIsEditing: Function,
    playlistItemInEdition: IPlaylistItem | null,
    setPlaylistItemInEdition: Function,
};

export default function PlaylistItemEdition({
                                         token,
                                         setRefresh,
                                         isEditing,
                                         setIsEditing,
                                         playlistItemInEdition,
                                         setPlaylistItemInEdition,
                                     }: Props) {

    function handleCancelClick() {
        setIsEditing(false)
    }

    async function handlePlaylistItemUpdate(playlistItemId: number, playlistItem: IPlaylistItemPayload) {
        console.log(playlistItem);

        setIsEditing(false)
        setPlaylistItemInEdition({})

        await apiUpdatePlaylistItem(playlistItemId, playlistItem, token)
        toast.success("Item updated successfully")
        setRefresh(true);
    }

    return (
        <>
            {isEditing && playlistItemInEdition ? <FormPlaylistItem albumId={playlistItemInEdition.album.id} playlistItemId={playlistItemInEdition.id} playlistItem={playlistItemInEdition}
                            token={token}
                            cancelButtonCallback={handleCancelClick} submitButtonCallback={handlePlaylistItemUpdate}/> : ""}
        </>
    )
}