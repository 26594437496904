import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export enum ModifierKey {
    Ctrl,
    Alt,
    Shift,
}

export const useKeyPress = (keys: any, callback: any, modKey: ModifierKey | null = null, node = null) => {
    const callbackRef = useRef(callback);
    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            switch (modKey) {
                case ModifierKey.Ctrl: 
                    if (!event.ctrlKey) {return}
                    break;
                case ModifierKey.Alt:
                    if (!event.altKey) {return}
                    break;
                case ModifierKey.Shift:
                    if (!event.shiftKey) {return}
                    break;
            }
            if (keys.some((key: any) => event.key === key)) {
                callbackRef.current(event);
            }
        },
        [keys, modKey]
    )

    useEffect(() => {
        const targetNode = node ?? document;
        targetNode && targetNode.addEventListener("keydown", handleKeyPress);

        return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress, node])
}