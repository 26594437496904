import React, { useContext } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useState} from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ConfirmDialog from "../ConfirmDialog";
import PageSelector from "../PageSelector";
import PlaylistItemEdition from '../forms/PlaylistItemEdition';
import PlaylistItem from "../items/PlaylistItem";
import { IPlaylistItem, IPlaylistItemPayload } from '../../@types/playlistItem';
import { IPaginationDataMeta } from '../../@types/paginationData';
import { apiCreatePlaylistItem, apiDeletePlaylistItem } from '../../services/PlaylistItemService';
import { UserContext } from '../../contexts/UserContext';
import { UserContextType } from '../../@types/user';
import { ModifierKey, useKeyPress } from '../../hooks/KeyPress';
import AlbumEdition from '../forms/AlbumEdition';

type Props = {
    playlistItemList: IPlaylistItem[] | null,
    setRefresh: Function,
    paginationData: IPaginationDataMeta | null,
    currentPage: number,
    setCurrentPage: Function,
    baseUrl: string,
    token: string,
}

export default function PlaylistItemList({
    playlistItemList, 
    setRefresh,
    paginationData,
    currentPage,
    setCurrentPage,
    baseUrl='/',
    token
}: Props) {
    const {userProfile} = useContext(UserContext) as UserContextType;
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [playlistItemInEdition, setPlaylistItemInEdition] = useState<IPlaylistItem | null>(null);
    useKeyPress(["n"], handleAddClick, ModifierKey.Alt);

    function handleAddClick(e: any) {
        e.preventDefault();
        if (token !== null) {
            setPlaylistItemInEdition(null);
            setIsAdding(true);
        }
    }

    function handleEditClick(playlistItemId: number, tempNote: number | null = null) {
        let playlistItemTemp = Object.assign({}, playlistItemList?.find(playlistItem => playlistItem.id === playlistItemId));
        if (tempNote && playlistItemTemp) {
            playlistItemTemp.listened = true;
            playlistItemTemp.note = tempNote;
        }
        setPlaylistItemInEdition((playlistItemTemp ? playlistItemTemp : null));
        setIsEditing(true);
    }

    function handleDeleteClick(playlistItemId: number) {
        const playlistItemTemp = playlistItemList?.find(playlistItem => playlistItem.id === playlistItemId);
        setPlaylistItemInEdition((playlistItemTemp ? playlistItemTemp : null));
        setConfirm(true);
    }

    function handleConfirmDialogCancel() {
        setPlaylistItemInEdition(null);
        setConfirm(false);
    }

    function onItemOpen(element: HTMLDetailsElement) {
        const elementId = element.id;
        let elements = document.querySelectorAll('details');

        elements.forEach((element) => {
            if (element.id && element.id !== elementId) {
                element.open = false;
            }
        });
    }

    const addPlaylistItem = async (albumId: number, listened: boolean = false) => {
        setIsAdding(false);
        if (!isNaN(albumId)) {
            let playlistItem: IPlaylistItemPayload={
                'note': -1,
                'listened': false,
                'listeningDate': null,
                'comment': null,
                'isPrivate': false,
                'favourite': false,
                'albumId': albumId,
            }

            let playlistItemAdded = await apiCreatePlaylistItem(playlistItem, token);   
            if (listened) {
                setPlaylistItemInEdition(playlistItemAdded);
                setIsEditing(true);
            } else {
                setRefresh(true);
                toast.success(`${playlistItemAdded.album.artist} - ${playlistItemAdded.album.title} added to playlist`, {
                    onClick: (() => navigate('/my-list'))
                });
            }
        }
    }

    async function deletePlaylistItem() {
        if (playlistItemInEdition) {
            return apiDeletePlaylistItem(playlistItemInEdition.id, token).then(response => {
                if (response.ok) {
                    toast.success("Item deleted successfully")
                    setRefresh(true)
                } else {
                    console.log(response)
                }
                setPlaylistItemInEdition(null)
            });
        }

        return null;
    }

    function changePage(pageIndex: number) {
        const urlParams = window.location.search.substring(1);
        let destinationUrl = baseUrl;
        if (destinationUrl !== "" && destinationUrl !== "/") {
            destinationUrl += "/";
        }
        destinationUrl += "page/" + pageIndex + "?" + urlParams;
        console.log(destinationUrl);

        setCurrentPage(pageIndex);
        navigate(destinationUrl);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <div className="main-button-container">
                {token !== null ? (
                    <button className="main-button"
                            title="Add an album in the playlist"
                            onClick={handleAddClick}>
                        <AddIcon/>
                    </button>
                ) : ""}
            </div>

            {/* {isAdding ? <SearchAlbum callback={addPlaylistItem} token={token} setRefresh={setRefresh} withCreation={true}/> : ""} */}
            <AlbumEdition
                token={token}
                setRefresh={setRefresh}
                isEditing={false}
                setIsEditing={() => {}}
                isAdding={isAdding}
                setIsAdding={setIsAdding}
                albumInEdition={null}
                setAlbumInEdition={() => {}}
                callback={addPlaylistItem}
                addingPlaylistItem={true}
            />
            <PlaylistItemEdition 
                token={token}
                setRefresh={setRefresh}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                playlistItemInEdition={playlistItemInEdition}
                setPlaylistItemInEdition={setPlaylistItemInEdition}
            />

            <ConfirmDialog
                title={"Remove item?"}
                content={"Do you really want to remove this item from your  playlist?"}
                onConfirm={deletePlaylistItem}
                onCancel={handleConfirmDialogCancel}
                openState={confirm}
                setOpenState={setConfirm}
                cancelButtonText='No'
                confirmButtonText='Yes'
            />

            { playlistItemList?.length === 0 ? <h3 className='text-center'>Nothing here :c</h3> : ""}
            { playlistItemList?.map((item) => <PlaylistItem key={item.id} id={item.id} item={item} token={token} 
                                                editAlbumButtonCallback={null} deleteAlbumButtonCallback={null}
                                                editItemButtonCallback={handleEditClick} deleteItemButtonCallback={handleDeleteClick} addButtonCallback={addPlaylistItem}
                                                onItemOpenCallback={onItemOpen}
                                                owned={userProfile?.id === item.user.id} />) }
            <PageSelector changePage={changePage} paginationData={paginationData}/>
        </>
    );
}