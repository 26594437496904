import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { apiGetConfiguration } from '../services/SecurityService';

export default function TermsOfServicePage() {
    document.title = "Terms of service | "+process.env.REACT_APP_TITLE!;
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        async function getLegal() {
            apiGetConfiguration().then((data: any) => {
                const termsOfService = data.get("termsofservice");
                if (termsOfService && termsOfService !== "") {
                    setMarkdown(termsOfService)
                } else {
                    window.location.href = '/';
                }
            })
        }

        getLegal();
    })

    return (
        <main>
            {markdown !== "" ? 
                <Markdown>{markdown}</Markdown> :
                <h2 className='text-center'>Loading ...</h2>
            }   
        </main>
    );
}