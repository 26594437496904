import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useCallback, useEffect} from "react";

type Props = {
    title: string,
    content: string,
    onConfirm: Function,
    openState: boolean,
    setOpenState: Function,
    cancelButtonText: string,
    confirmButtonText: string,
    onCancel: Function | null
}
export default function ConfirmDialog({
                                          title,
                                          content,
                                          onConfirm,
                                          openState,
                                          setOpenState,
                                          cancelButtonText = "No",
                                          confirmButtonText = "Yes",
                                          onCancel = null
                                      }: Props) {

    // handle what happens on key press
    const handleKeyPress = useCallback((event: any) => {
        if (event.key === "Enter") {
            let confirmButton = document.getElementById("dialog-confirm-button")
            if (confirmButton !== null) {
                confirmButton.click()
            }
        }
    }, [])

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keypress', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [handleKeyPress]);


    const handleClose = () => {
        if (onCancel !== null && onCancel !== undefined) {
            onCancel();
        }
        setOpenState(false);
    };

    const handleConfirm = () => {
        onConfirm();
        setOpenState(false);
    };

    return (
        <Dialog
            open={openState}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>{cancelButtonText}</Button>
                <Button id="dialog-confirm-button" onClick={handleConfirm}>{confirmButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
}