import React, { useContext, useState } from 'react';
import {toast} from 'react-toastify';
import { UserContext } from '../../contexts/UserContext';
import { IUserAdmin, IUserAdminPayload, UserContextType } from '../../@types/user';
import AdminFormUser from './AdminFormUser';
import { apiAdminDeleteUser, apiAdminUpdateUser } from '../../services/AdminService';
import ConfirmDialog from '../ConfirmDialog';

type Props = {
    userList: IUserAdmin[],
    setUserList: Function,
}

export default function AdminUserList({userList, setUserList}: Props) {
    const {userProfile} = useContext(UserContext) as UserContextType
    const [userInEdition, setUserInEdition] = useState<IUserAdmin | null>(null);
    const [confirm, setConfirm] = useState(false);
    const [open, setOpen] = useState(false);

    const onUserCheckBoxChange = (e: any) => {       
        if (e.target.checked) {
            userList.forEach((user) => {
                if ("user-"+user.id !== e.target.id) {
                    let checkBox = document.getElementById("user-"+user.id) as HTMLInputElement;
                    checkBox.checked = false;
                }
            });
        }
    }

    const deleteUserClick = () => {
        setConfirm(true);
    }

    const deleteUser = async () => {
        const userId = document.querySelector("input[name='checkbox-user']:checked")?.id.replace('user-', '');
        const userTemp = userList?.find(user => user.id.toString() === userId);

        if (userTemp && userProfile) {
            apiAdminDeleteUser(userProfile.token, userTemp.id).then((response) => {
                if (response.status === 204) {
                    setUserList(userList.filter((user) => user.id !== userTemp.id));
                    toast.success("User successfully deleted");
                } else {
                    response.json().then((json) => {
                        toast.error(json.message);
                    });
                }
            });
        }
    }

    const updateUserClick = () => {
        const userId = document.querySelector("input[name='checkbox-user']:checked")?.id.replace('user-', '');
        const userTemp = userList?.find(user => user.id.toString() === userId);

        if (userTemp) {
            setUserInEdition(userTemp);
            setOpen(true);
        }
    }

    const saveUpdatedUser = async (user: IUserAdminPayload) => {
        if (userProfile) {
            const updatedUser = await apiAdminUpdateUser(userProfile.token, user);
            
            const userListTemp = userList.map((user) => {
                if (user.id === updatedUser.id) {
                    return updatedUser;
                }
                return user;
            });

            setUserList(userListTemp);
        }

        setUserInEdition(null);
    }

    const cancelUserUpdate = () => {
        setUserInEdition(null);
    }

    return (
        <>
            <h2>Admin user list</h2>
            <button onClick={updateUserClick}>update</button>
            <button onClick={deleteUserClick}>delete</button>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>E-mail</th>
                        <th>Username</th>
                        <th>Email verified at</th>
                        <th>Admin</th>
                    </tr>
                </thead>
                <tbody>
                    {userList.map((user) => 
                        <tr key={user.id}>
                            <td><input id={"user-"+user.id} name="checkbox-user" type='checkbox' onChange={onUserCheckBoxChange}/></td>
                            <td>{user.id}</td>
                            <td>{user.email}</td>
                            <td>{user.name}</td>
                            <td>{user.email_verified_at?.toString()}</td>
                            <td>{user.is_admin}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {
                userInEdition !== null ? 
                <AdminFormUser 
                    open={open} 
                    setOpen={setOpen}
                    cancelButtonCallback={cancelUserUpdate}
                    submitButtonCallback={saveUpdatedUser}
                    user={userInEdition}
                /> :
                ""
            }

            <ConfirmDialog 
                title='Delete user'
                content='Do you really want to delete this user? Its playlist items will not be deleted, but cannot be reattributed after the deletion'
                openState={confirm}
                setOpenState={setConfirm}
                onConfirm={deleteUser}
                onCancel={null}
                confirmButtonText='Delete'
                cancelButtonText='Cancel'
            />
        </>
    );
}