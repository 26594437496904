import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { apiGetConfiguration } from '../services/SecurityService';

export default function PrivacyPolicyPage() {
    document.title = "Privacy Policy | "+process.env.REACT_APP_TITLE!;
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        async function getLegal() {
            apiGetConfiguration().then((data: any) => {
                const privacyPolicy = data.get("privacypolicy");
                if (privacyPolicy && privacyPolicy !== "") {
                    setMarkdown(privacyPolicy)
                } else {
                    window.location.href = '/'
                }
            })
        }

        getLegal();
    })

    return (
        <main>
            {markdown !== "" ? 
                <Markdown>{markdown}</Markdown> :
                <h2 className='text-center'>Loading ...</h2>
            }   
        </main>
    );
}