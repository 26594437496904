import React, { useContext, useState } from 'react';
import {toast} from "react-toastify";
import { IPaginationDataMeta } from '../../@types/paginationData';
import { IAlbum } from '../../@types/album';
import AlbumItem from '../items/AlbumItem';
import { useNavigate } from 'react-router-dom';
import PageSelector from '../PageSelector';
import AlbumEdition from '../forms/AlbumEdition';
import { UserContext } from '../../contexts/UserContext';
import { UserContextType } from '../../@types/user';
import ConfirmDialog from '../ConfirmDialog';
import { apiDeleteAlbum } from '../../services/AlbumService';
import { IPlaylistItemPayload } from '../../@types/playlistItem';
import { apiCreatePlaylistItem } from '../../services/PlaylistItemService';

type Props = {
    albumList: IAlbum[] | null,
    setRefresh: Function,
    paginationData: IPaginationDataMeta | null,
    currentPage: number,
    setCurrentPage: Function,
    baseUrl: string,
}

export default function AlbumList({
    albumList, 
    setRefresh,
    paginationData,
    currentPage,
    setCurrentPage,
    baseUrl='/',
}: Props) {
    const navigate = useNavigate();
    const {userProfile} = useContext(UserContext) as UserContextType;
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [albumInEdition, setAlbumInEdition] = useState<IAlbum | null>(null)

    const changePage = (pageIndex: number) => {
        const urlParams = window.location.search.substring(1)
        let destinationUrl = baseUrl;
        if (destinationUrl !== "" && destinationUrl !== "/") {
            destinationUrl += "/";
        }
        destinationUrl += "page/" + pageIndex + "?" + urlParams;

        setCurrentPage(pageIndex);
        navigate(destinationUrl);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const callbackEditClick = (albumId: number) => {
        const albumItemTemp = albumList?.find(album => album.id === albumId);
        setAlbumInEdition(albumItemTemp!);
        setIsEditing(true);
    }

    const callbackDeleteClick = (album: IAlbum) => {
        setAlbumInEdition(album)
    }

    const addToPlaylist = async (albumId: number) => {
        if (userProfile) {
            setIsAdding(false);
            if (!isNaN(albumId)) {
                let playlistItem: IPlaylistItemPayload={
                    'note': -1,
                    'listened': false,
                    'listeningDate': null,
                    'comment': null,
                    'isPrivate': false,
                    'favourite': false,
                    'albumId': albumId,
                }

                let playlistItemAdded = await apiCreatePlaylistItem(playlistItem, userProfile.token);   
                setRefresh(true);
                toast.success(`${playlistItemAdded.album.artist} - ${playlistItemAdded.album.title} added to playlist`, {
                    onClick: (() => navigate('/my-list'))
                });
            }
        }
    }

    const confirmDialogCancel = () => {
        setAlbumInEdition(null);
        setConfirm(false);
    }

    const deleteAlbum = () => {
        apiDeleteAlbum(albumInEdition!.id, userProfile!.token)
        confirmDialogCancel();
    }

    function onItemOpen(element: HTMLDetailsElement) {
        const elementId = element.id;
        let elements = document.querySelectorAll('details');

        elements.forEach((element) => {
            if (element.id && element.id !== elementId) {
                element.open = false;
            }
        });
    }

    return (
        <>
            <AlbumEdition 
                token={(userProfile) ? userProfile.token : ""}
                setRefresh={setRefresh}
                isAdding={isAdding}
                setIsAdding={setIsAdding}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                albumInEdition={albumInEdition}
                setAlbumInEdition={setAlbumInEdition}
                callback={()=>{}}
                addingPlaylistItem={false}
            />

            <ConfirmDialog
                title={"Remove item?"}
                content={"Do you really want to delete this album?"}
                onConfirm={deleteAlbum}
                onCancel={confirmDialogCancel}
                openState={confirm}
                setOpenState={setConfirm}
                cancelButtonText='No'
                confirmButtonText='Yes'
            />

            { albumList?.length === 0 ? <h3 className='text-center'>Nothing here :c</h3> : ""}
            { albumList?.map((album) => <AlbumItem 
                                            key={album.id} 
                                            album={album}
                                            token={userProfile ? userProfile.token : ""}
                                            editButtonCallback={callbackEditClick}
                                            deleteButtonCallback={callbackDeleteClick}
                                            addToPlaylistCallback={addToPlaylist}
                                            onItemOpenCallback={onItemOpen}
                                        />) }
                <PageSelector changePage={changePage} paginationData={paginationData}/>
        </>
    )
}