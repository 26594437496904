import React, { useState } from "react"
import { toast } from "react-toastify";
import ShareIcon from '@mui/icons-material/Share';
import XIcon from '@mui/icons-material/X';
import RedditIcon from '@mui/icons-material/Reddit';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
    className: string,
    fulltext: string,
    text: string,
    title: string,
    url: string,
    permalink: string,
};

export default function ShareComponent({className="", fulltext="", text="", title="", url, permalink}: Props) {
    const [dropdownDisplay, setDropdownDisplay] = useState(false);
    const SharingPlatform = {
        MASTODON: 'MASTODON',
        TWITTER: 'TWITTER',
        REDDIT: 'REDDIT',
        FACEBOOK: 'FACEBOOK',
        MAIL: 'MAIL',
        COPYLINK: 'COPYLINK',
    }

    function twitterShare() {
        //I know it's X now, when the domain Twitter will be deactivated, this sharing button will not work, on purpose
        // Build the URL
        //https://twitter.com/intent/tweet?url=https://crunchify.com/list-of-all-social-sharing-urls-for-handy-reference-social-media-sharing-buttons-without-javascript/&text=Test
        const shareUrl = "https://twitter.com/intent/tweet?url="+encodeURIComponent(url)+"&text="+encodeURIComponent(fulltext);

        // Open a window on the share page
        window.open(shareUrl, '_blank');
    }

    function redditShare() {
        // Build the URL
        const shareUrl = "https://www.reddit.com/submit?url="+encodeURIComponent(url)+"&title="+encodeURIComponent(title)+"&type=LINK";

        // Open a window on the share page
        window.open(shareUrl, '_blank');
    }

    function facebookShare() {
        // Build the URL
        const shareUrl = "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(url)+"&text=" + encodeURIComponent(text);

        // Open a window on the share page
        window.open(shareUrl, '_blank');
    }

    function mailShare() {
        // Build the URL
        const mailtoUrl = "mailto:?subject="+encodeURIComponent(title)+"&body="+encodeURIComponent(text)+"%0D%0A%0D%0A"+encodeURIComponent(url);

        // Open a window on the share page
        window.location.href = mailtoUrl;
    }

    function copyLink() {
        navigator.clipboard.writeText(window.location.host+permalink).then(function() {
            toast.success("Permalink copied");
        }, function() {
            toast.error('Copy error')
        });
    }

    function mastodonShare() {
        let domain = localStorage.getItem('mastodon-domain');
        const content = fulltext

        //check if mastodon domain is in local storage before asking for it
        if (domain === "" || domain == null) {
            // Get the Mastodon domain
            domain = prompt("Enter your Mastodon domain", "mastodon.social");

            if (domain === "" || domain == null){
                return;
            } else {
                localStorage.setItem('mastodon-domain', domain)
            }
        }

        // Build the URL
        const shareUrl = "https://" + domain + "/share?text=" + encodeURIComponent(content);

        // Open a window on the share page
        window.open(shareUrl, '_blank');
    }

    function share(platform: string) {
        switch (platform) {
            case SharingPlatform.MASTODON:
                mastodonShare();
                break;
            case SharingPlatform.TWITTER:
                twitterShare();
                break;
            case SharingPlatform.REDDIT:
                redditShare();
                break;
            case SharingPlatform.FACEBOOK:
                facebookShare();
                break;
            case SharingPlatform.MAIL:
                mailShare();
                break;
            case SharingPlatform.COPYLINK:
                copyLink();
                break;
            default:
                break;
        }
    }

    return (
        <>
            <ul className="share-buttons" style={{display: dropdownDisplay ? "inline-block" : "none"}}>
                <li onClick={(e) => share(SharingPlatform.MASTODON)}><span className="logos--mastodon"></span></li>
                <li onClick={(e) => share(SharingPlatform.REDDIT)}><RedditIcon htmlColor="#fd4500"/></li>
                <li onClick={(e) => share(SharingPlatform.FACEBOOK)}><FacebookIcon htmlColor="#0093f8" /></li>
                <li onClick={(e) => share(SharingPlatform.TWITTER)}><XIcon/></li>
                <li onClick={(e) => share(SharingPlatform.MAIL)}><MailOutlineIcon /></li>
                <li onClick={(e) => share(SharingPlatform.COPYLINK)}><ContentCopyIcon /></li>
            </ul>
            <button title="Share" className={className} onClick={(e) => setDropdownDisplay(!dropdownDisplay)}>
                <ShareIcon fontSize={"small"}/>
            </button>
        </>
    )
}