import { IUserAdmin, IUserAdminPayload } from "../@types/user";

const apiUrl = process.env.REACT_APP_API_URL;

export const apiAdminGetUserList = async (token: string): Promise<IUserAdmin[]> => {
    const response = await (fetch(`${apiUrl}/admin/users`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    const json = await response.json();
    return json.data;
}

export const apiAdminUpdateUser = async (token: string, user: IUserAdminPayload): Promise<IUserAdmin> => {
    const response = await (fetch(`${apiUrl}/admin/users/${user.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(user)
    }));

    const json = await response.json();
    return json.data;
}

export const apiAdminDeleteUser = async (token: string, userId: number): Promise<Response> => {
    const response = await (fetch(`${apiUrl}/admin/users/${userId}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    }));

    return response;
}

export const apiAdminStoreConfiguration = async (token: string, key: string, value: string): Promise<Response> => {
    const response = await (fetch(`${apiUrl}/admin/configuration`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({key: key, value: value})
    }));

    return response;
}