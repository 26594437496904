import { IUserProfile } from "../@types/user";

const apiUrl = process.env.REACT_APP_API_URL;

export const apiGetUserProfile = async (token: string): Promise<IUserProfile> => {
    const response = await (fetch(`${apiUrl}/user/profile`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    const json = await response.json();
    return json.data;
}

export const apiPatchUserProfile = async (token: string, name: string) => {
    const payload = {name: name};

    const response = await (fetch(`${apiUrl}/user/profile`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }));

    const json = await response.json();
    return json;
}

export const apiUpdateUserPassword = async(token: string, email: string, currentPassword: string, newPassword: string, newPasswordConfirmation: string) => {
    const payload = {
        email: email,
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
        token: token,
    }
    const response = await(fetch(`${apiUrl}/user/change-password`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }));

    const json = await response.json();
    return json;
}

export const apiGetUserRole = async (token: string) => {
    const response = await (fetch(`${apiUrl}/user/role`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }));

    const json = await response.json();
    return json;
}

export const apiGetConnectedDevices = async (token: string) => {
    const response = await (fetch(`${apiUrl}/user/connected-devices`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }));

    const json = await response.json();
    return json;
}

export const apiRevokeAuthToken = async (token: string, deviceName: string) => {
    const response = await (fetch(`${apiUrl}/user/revoke-token`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({'device': deviceName})
    }));

    return response;
}