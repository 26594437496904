import dayjs from "dayjs";
import React, {useCallback, useEffect, useState} from "react"
import {Checkbox, FormControlLabel, Rating, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {DateTimeField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import { IPlaylistItem } from "../../@types/playlistItem";
import { IAlbum } from "../../@types/album";
import { apiGetOneAlbumById } from "../../services/AlbumService";

type Props = {
    albumId: number,
    cancelButtonCallback: Function,
    submitButtonCallback: Function,
    token: string,
    playlistItemId: number,
    playlistItem: IPlaylistItem | null,
};

export default function FormPlaylistItem({albumId, cancelButtonCallback, submitButtonCallback, token, playlistItemId=0, playlistItem=null}: Props) {
    const [open, setOpen] = useState(true);
    const [album, setAlbum] = useState<IAlbum | null>(null);
    const [listened, setListened] = useState(playlistItemId !== 0 && playlistItem ? (playlistItem.listened) : false)
    const [comment, setComment] = useState(playlistItemId !== 0 && playlistItem ? playlistItem.comment : "")
    const [note, setNote] = useState<number | null>(playlistItemId !== 0 && playlistItem ? playlistItem.note : 0)
    const [isPrivate, setIsPrivate] = useState(playlistItemId !== 0 && playlistItem ? playlistItem.isPrivate : false)
    const [favourite, setFavourite] = useState(playlistItemId !== 0 && playlistItem ? playlistItem.favourite: false)
    const [listeningDate, setListeningDate] = useState(playlistItemId !== 0 && playlistItem  && playlistItem.listeningDate !== null ? playlistItem.listeningDate : new Date().toISOString())

    // handle what happens on key press
    const handleKeyPress = useCallback((event: any) => {
        if (event.key === "Enter" && (event.target.id !== 'comment' || event.ctrlKey)) {
            let submitButton = document.getElementById("save-button")
            if (submitButton !== null) {
                submitButton.click();
            }
        }
    }, []);

    useEffect(() => {
        apiGetOneAlbumById(albumId, token).then((data) => {
            setAlbum(data);
        });

        // attach the event listener
        document.addEventListener('keypress', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [handleKeyPress, albumId, token]);

    function handleClose(e: any, reason = "") {
        e.preventDefault()
        if (reason === "backdropClick") {
            return
        }
        setOpen(false)
        cancelButtonCallback()
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        if (album === null) {
            return;
        }

        if (isNaN(album.id)) {
            return;
        }

        const listenedDate = new Date(listeningDate);

        let playlistItem = {
            'albumId': album.id,
            'comment': comment,
            'listened': listened,
            'note': note,
            'isPrivate': isPrivate,
            'favourite': favourite,
            'listeningDate': (listened ? listenedDate.toISOString() : null),
        }

        if (playlistItemId !== 0) {
            submitButtonCallback(playlistItemId, playlistItem)
        } else {
            submitButtonCallback(playlistItem)
        }
    }

    return (
        <>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle>Playlist Item</DialogTitle>
                <DialogContent>
                    <p>{ album?.artist } - { album?.title } <br/> <span className="font-small">{album?.genre.join(', ')}</span></p>
                    <FormControlLabel
                        control={<Checkbox
                            id="listened"
                            name="listened"
                            checked={listened}
                            onChange={e => setListened(e.target.checked)}
                        />} label="Listened"/>
                    <FormControlLabel 
                        control={<Checkbox 
                            id="favourite"
                            name="favourite"
                            checked={favourite}
                            onChange={e => setFavourite(e.target.checked)}
                        />} label="Favourite"/>
                    &nbsp;&nbsp;
                    <FormControlLabel
                    control={<Checkbox
                        id="private"
                        name="private"
                        checked={isPrivate}
                        onChange={e => setIsPrivate(e.target.checked)}
                    />} label="Private"/>
                    <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimeField
                            margin="dense"
                            id="listeningDate"
                            name="listeningDate"
                            label="Listening Date"
                            variant="standard"
                            fullWidth
                            format="YYYY-MM-DD HH:mm:ss"
                            defaultValue={dayjs(listeningDate)}
                            disabled={!listened}
                            onChange={(date) => {
                                if (date) {
                                    const d = new Date(date.toDate()).toISOString();
                                    setListeningDate(d);
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <br/>
                    <Rating
                        id="note"
                        name="note"
                        value={note}
                        precision={1}
                        onChange={(e, value) => {setNote(value)}}/>
                    <TextField
                        margin="dense"
                        id="comment"
                        name="comment"
                        label={"Comment"}
                        multiline
                        maxRows={10}
                        fullWidth
                        variant="standard"
                        defaultValue={comment}
                        onChange={e => setComment(e.target.value)}
                    />
                    <p className="font-small">The comment will be rendered with <a href="https://daringfireball.net/projects/markdown/syntax">Markdown syntax</a>.</p>
                </DialogContent>
                <DialogActions>
                    <button className="action-button" onClick={handleClose}>Cancel</button>
                    <button className="action-button" id="save-button"
                            onClick={handleSubmit}>Save</button>
                </DialogActions>
            </Dialog>
        </>
    );
}