import { debounce } from "@mui/material/utils";
import StarsIcon from '@mui/icons-material/Stars';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ModifierKey, useKeyPress } from "../hooks/KeyPress";
import { useRef } from "react";

type Props = {
    setSearchText: Function,
    showListened: boolean | undefined,
    setShowListened: Function | null,
    showFavourite: boolean | undefined,
    setShowFavourite: Function | null,
}

export default function QuickSearch({setSearchText, showListened, setShowListened, showFavourite, setShowFavourite}: Props) {
    const searchInput = useRef<HTMLInputElement | null>(null);
    const classes = setShowListened !== null ? 'quick-search-text' : 'quick-search-text full-width';
    useKeyPress(["k"], giveFocusToSearchInput, ModifierKey.Alt)

    function giveFocusToSearchInput() {
        console.log(searchInput);
        if (searchInput.current !== null) {
            searchInput.current.focus();
        }
    }

    function favouriteInput() {
        if (setShowFavourite !== null) {
            return (
                <span className="quick-search-icon" title="Show favourite" onClick={() => setShowFavourite(!showFavourite)}>
                <StarsIcon 
                    sx={{verticalAlign: 'middle'}}
                    htmlColor={showFavourite ? "#faaf00" : "grey"}
                />
                </span>
            )
        }

        return "";
    }

    function listenedInput() {
        if (setShowListened !== null) {
            return (
                <span className="quick-search-icon" title="Show listened" onClick={() => setShowListened(!showListened)}>
                    <CheckCircleIcon 
                        sx={{verticalAlign: 'middle'}}
                        htmlColor={showListened ? "green" : "grey"}
                    />
                </span>
            )
        }

        return "";
    }

    return (
        <div className="filter-buttons">
            <input 
                ref={searchInput}
                placeholder="Quick search..." 
                name="quick-search-text" 
                onChange={debounce((e) => {
                    setSearchText(e.target.value);
                }, 500)} 
                className={classes}
            />
            {favouriteInput()}
            {listenedInput()}
        </div>
    )
}