import { Autocomplete, Chip, TextField } from "@mui/material";
import { getQueryVariable } from "../util/urlParam";
import { useContext, useEffect, useState, useCallback } from "react";
import { apiAddAlbum, apiGetBandcampItemDetails, apiGetGenres } from "../services/AlbumService";
import { UserContextType } from "../@types/user";
import { UserContext } from "../contexts/UserContext";
import { apiCreatePlaylistItem } from "../services/PlaylistItemService";
import { IPlaylistItemPayload } from "../@types/playlistItem";

export default function PlaylistAddPage() {
    const styles = getComputedStyle(document.body);
    const { userProfile } = useContext(UserContext) as UserContextType;

    const [url, setUrl] = useState(decodeURIComponent(getQueryVariable('url')));
    const [artist, setArtist] = useState("");
    const [title, setTitle] = useState("");
    const [genre, setGenre] = useState<string[]>([]);
    const [saving, setSaving] = useState(false);

    const [errorUrl, setErrorUrl] = useState(false);
    const [errorArtist, setErrorArtist] = useState(false);
    const [errorTitle, setErrorTitle] = useState(false);
    const [errorGenre, setErrorGenre] = useState(false);
    const [genreList, setGenreList] = useState<string[]>([]);


    // handle what happens on key press
    const handleKeyPress = useCallback((event: any) => {
        if (event.key === "Enter") {
            let submitButton = document.getElementById("save-button");
            if (submitButton !== null) {
                submitButton.click();
            }
        }
    }, []);

    useEffect(() => {
        apiGetGenres().then((data) => { if (data) {setGenreList(data)}});

        apiGetBandcampItemDetails(url, userProfile!.token).then((data) => {
            if (data) {
                setArtist(decodeString(data.artist));
                setTitle(decodeString(data.title));
        
                // Dispatch event to change the input label display
                const artistField = document.getElementById('artist')
                const titleField = document.getElementById('title')
                if (artistField !== null && titleField !== null) {
                    artistField.dispatchEvent(new Event('filled'))
                    titleField.dispatchEvent(new Event('filled'))
                }
            }
        })

        // attach the event listener
        document.addEventListener('keypress', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [url, userProfile, handleKeyPress]);

    const decodeString = (input: string): string => {
        let div = document.createElement('div');
        div.innerHTML = input

        if (div && div.firstChild && div.firstChild.nodeValue) {
            return div.firstChild.nodeValue;
        } else {
            return "";
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!checkRequiredField()) {
            return
        }

        setSaving(true);

        let album = {
            'url': url,
            'artist': artist,
            'title': title,
            'genre': genre,
        }

        if (userProfile) {
            apiAddAlbum(album, userProfile.token).then((data) => {
                let playlistItem: IPlaylistItemPayload={
                    'note': -1,
                    'listened': false,
                    'listeningDate': null,
                    'comment': null,
                    'isPrivate': false,
                    'favourite': false,
                    'albumId': data.id,
                }

                apiCreatePlaylistItem(playlistItem, userProfile.token).then(() => {
                    window.close();
                })
            })
        }
    }

    const checkRequiredField = () => {
        let fieldsOk = true
        setErrorUrl(false)
        setErrorArtist(false)
        setErrorTitle(false)
        setErrorGenre(false)

        fieldsOk = fieldsOk && checkField(url, setErrorUrl, /^(ftp|http|https):\/\/[^ "]+$/)
        fieldsOk = fieldsOk && checkField(artist, setErrorArtist)
        fieldsOk = fieldsOk && checkField(title, setErrorTitle)
        fieldsOk = fieldsOk && checkField(genre, setErrorGenre)

        return fieldsOk
    }

    const checkField = (value: any, setError: Function, regex: RegExp | null = null) => {
        let fieldOk = true
        if ((value === null || value === "") || (regex !== null && !regex.test(value))) {
            setError(true)
            fieldOk = false
        }

        return fieldOk
    }

    return (
        <main>
            <TextField
                InputProps={{className: 'mui-input'}}
                InputLabelProps={{className: 'mui-input'}}
                autoFocus
                disabled={saving}
                margin="dense"
                id="url"
                name="url"
                label={"URL"}
                fullWidth
                variant="standard"
                required={true}
                error={errorUrl}
                helperText={errorUrl ? "URL is empty or invalid" : ""}
                value={url}
                onChange={e => setUrl(e.target.value)}
            />
            <TextField
                InputProps={{className: 'mui-input'}}
                InputLabelProps={{className: 'mui-input'}}
                margin="dense"
                disabled={saving}
                id="artist"
                name="artist"
                label={"Artist"}
                fullWidth
                variant="standard"
                required={true}
                error={errorArtist}
                helperText={errorArtist ? "Artist is required" : ""}
                value={artist}
                onChange={e => setArtist(e.target.value)}
            />
            <TextField
                InputProps={{className: 'mui-input'}}
                InputLabelProps={{className: 'mui-input'}}
                margin="dense"
                disabled={saving}
                id="title"
                name="title"
                label={"Title"}
                fullWidth
                variant="standard"
                required={true}
                error={errorTitle}
                helperText={errorTitle ? "Title is required" : ""}
                value={title}
                onChange={e => setTitle(e.target.value)}
            />
            <Autocomplete
                id="genre"
                multiple
                fullWidth
                disabled={saving}
                options={genreList}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        sx={{
                            backgroundColor: styles.getPropertyValue('--text'),
                            color: styles.getPropertyValue('--bg-accent')
                        }}
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                freeSolo
                onChange={(e, value) => setGenre(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{...params.InputProps, className: 'mui-input'}}
                        InputLabelProps={{className: 'mui-input'}}
                        variant="standard"
                        label="Genre"
                        error={errorGenre}
                        helperText={errorGenre ? "Genre is required" : ""}
                    />
                )}
            />

            <div className="text-center full-width">
                <button disabled={saving} className="action-button width-30" id="save-button"
                        onClick={handleSubmit}>{saving ? "Saving..." : "Add"}</button>
            </div>
        </main>
    );
}