import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PlaylistItemList from '../components/lists/PlaylistItemList';
import { IPlaylistItem } from '../@types/playlistItem';
import { IPaginationDataMeta } from '../@types/paginationData';
import { apiGetPlaylistItems } from '../services/PlaylistItemService';
import QuickSearch from '../components/QuickSearch';

type Props = {
    token: string
}

function HomePage({token}: Props) {
    document.title = process.env.REACT_APP_TITLE!;
    const links = {
        home: document.getElementById('home-link'),
        mylist: document.getElementById('my-list-link'),
        search: document.getElementById('search-link'),
    };
    if (links.home) links.home.classList.add('current-page');
    if (links.mylist) links.mylist.classList.remove('current-page');
    if (links.search) links.search.classList.remove('current-page');

    const {page} = useParams()
    const [albumList, setAlbumList] = useState<IPlaylistItem[]>([])
    const [currentPage, setCurrentPage] = useState(page !== null && page !== undefined && parseInt(page) !== 0 ? parseInt(page) : 1)
    const [paginationData, setPaginationData] = useState<IPaginationDataMeta | null>(null)
    const [refresh, setRefresh] = useState(false)
    const [searchText, setSearchText] = useState("")
    const urlParams = window.location.search.substring(1)

    useEffect(() => {
        setRefresh(false)

        async function fetchData() {
            let filterText = "&listened=1";
            if (searchText !== "") {
                filterText += `&search=${searchText}&fulltextsearch=1`
            }

            const datas = await apiGetPlaylistItems(filterText, token, currentPage, false);
            if (datas) {
                const albums = datas.items
                const meta = datas.meta

                if (albums.length > 0) {
                    setAlbumList(albums);
                    setPaginationData(meta);
                } else {
                    setCurrentPage(1)
                    window.history.pushState({},"",'/')
                }
            }
        }

        fetchData();
    }, [currentPage, refresh, token, urlParams, searchText]);

    return (
        <main>
            <QuickSearch 
                setShowListened={null}
                setSearchText={setSearchText}
                showListened={undefined}
                showFavourite={undefined}
                setShowFavourite={null}
            />
            <PlaylistItemList 
                playlistItemList={albumList} 
                setRefresh={setRefresh}
                paginationData={paginationData!}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                token={token} 
                baseUrl="/"
            /> 
        </main>
    )
}

export default HomePage;
