import React from "react"
import { IPaginationDataMeta } from "../@types/paginationData"

type Props = {
    paginationData: IPaginationDataMeta | null,
    changePage: Function,
}

export default function PageSelector({paginationData, changePage}: Props): React.JSX.Element {
    let pageList: any[] = [];

    if (paginationData) {
        paginationData.links.forEach((link) => {
            const page = (link.url ? link.url.split('=')[1] : 0);
            let label = link.label;
            if (label.toLowerCase().includes('previous')) {
                if (!link.url) {
                    return;
                }
                label = "<";
            }
            if (label.toLowerCase().includes('next')) {
                if (!link.url) {
                    return;
                }
                label = ">";
            }

            if (!link.active && link.url) {
                pageList.push(<li key={link.label}><button className="link-button" onClick={() => changePage((page))}>{label}</button></li>)
            } else {
                pageList.push(<li key={link.label}>{label}</li>)
            }
        });
    }

    return (
        <div className={"page-selector"}>
            <ul className="font-medium">
                {pageList}
            </ul>
        </div>
    )
}

