import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PlaylistItemList from '../components/lists/PlaylistItemList';
import { IPlaylistItem } from "../@types/playlistItem";
import { IPaginationDataMeta } from "../@types/paginationData";
import { apiGetPlaylistItems } from "../services/PlaylistItemService";
import QuickSearch from "../components/QuickSearch";

type Props = {
    token: string
};

function PlaylistPage({token}: Props) {
    const {userId} = useParams()
    document.title = (userId ? "User list" : "My list")+" | "+process.env.REACT_APP_TITLE!;
    const links = {
        home: document.getElementById('home-link'),
        mylist: document.getElementById('my-list-link'),
        search: document.getElementById('search-link'),
    };
    if (links.home) links.home.classList.remove('current-page');
    if (links.search) links.search.classList.remove('current-page');
    if (links.mylist) {
        if (userId === undefined) {
            links.mylist.classList.add('current-page');
        } else { 
            links.mylist.classList.remove('current-page');
        }   
    }

    const {page} = useParams()
    const [albumList, setAlbumList] = useState<IPlaylistItem[] | null>([])
    const [paginationData, setPaginationData] = useState<IPaginationDataMeta | null>(null)
    const [refresh, setRefresh] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [showListened, setShowListened] = useState(userId !== undefined);
    const [showFavourite, setShowFavourite] = useState(userId !== undefined);
    const [currentPage, setCurrentPage] = useState(page !== null && page !== undefined && parseInt(page) !== 0 ? parseInt(page) : 1)
    
    const urlParams = window.location.search.substring(1)

    useEffect(() => {
        setRefresh(false)

        async function fetchData() {
            let filterText = `&listened=${showListened ? 1 : 0}`;
            if (showFavourite) {
                filterText += '&favourite=1';
            }
            if (userId) {
                filterText += `&user_id=${userId}`;
            }
            if (searchText !== "") {
                filterText += `&search=${searchText}&fulltextsearch=1`;
            }

            const datas = await apiGetPlaylistItems(filterText, token, currentPage, (userId === undefined));

            if (datas) {
                const albums = datas.items;
                const meta = datas.meta;

                if (datas === null) {
                    setAlbumList([]);
                } else {
                    if (albums.length > 0) {
                        setAlbumList(albums);
                        setPaginationData(meta);
                    } else {
                        setAlbumList(albums);
                        setPaginationData(null);
                        setCurrentPage(1)
                        window.history.pushState({},"",'/my-list')
                    }
                }
            }
        }

        fetchData();
    }, [currentPage, refresh, token, urlParams, searchText, showListened, showFavourite, userId]);

    return (
        <main>
            <QuickSearch
                setShowListened={setShowListened}
                setSearchText={setSearchText}
                showListened={showListened}
                showFavourite={showFavourite}
                setShowFavourite={setShowFavourite}
            />
            <PlaylistItemList 
                playlistItemList={albumList} 
                setRefresh={setRefresh}
                paginationData={paginationData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                baseUrl={userId ? `/user-list/${userId}` : '/my-list'}
                token={token} 
            /> 
        </main>
    )
}

export default PlaylistPage;
