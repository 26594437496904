import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {Rating} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock'
import LaunchIcon from "@mui/icons-material/Launch";
import {getAlbumEmbed} from '../util/embed'
import { IPlaylistItem } from '../@types/playlistItem';
import { apiGetOnePlaylistItem } from '../services/PlaylistItemService';
import { useForceUpdate } from '../hooks/ForceUpdate';
import Markdown from 'react-markdown';

type Props = {
    token: string
}

export default function PlaylistItemPage({token}: Props) {
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const {id} = useParams();
    const [playlistItem, setPlaylistItem] = useState<IPlaylistItem | null>(null);
    const [comment, setComment] = useState('');
    const [listened, setListened] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [date, setDate] = useState<Date>();

    useEffect(() => {
        async function fetchAlbum() {
            if (id) {
                const response = await apiGetOnePlaylistItem(parseInt(id), token);

                if (response === null) {
                    navigate("/");
                } else {
                    setPlaylistItem(response);
                    setComment(response.listened ? response.comment : "");
                    setListened(response.listened);
                    setIsPrivate(response.isPrivate);
                    setDate(new Date(response.listeningDate));
                }
            }
        }

        fetchAlbum();
    }, [id, token, navigate]);

    const getPlaylistItemContent = () => {
        if (playlistItem !== null) {
            document.title = `${playlistItem.album.artist} - ${playlistItem.album.title} | `+process.env.REACT_APP_TITLE!;
            return (
                <div className="item">
                    <p>
                        <span style={{fontSize: "large", fontWeight: "bold"}}>
                            {playlistItem.album.artist} - {playlistItem.album.title}&nbsp;
                            <span className="text-no-bold font-small">
                                ({playlistItem.album.genre.join(', ')})
                            </span>
                        </span><br/>
                        {isPrivate ? <span title="Private"><LockIcon fontSize="small"/></span> : ""}
                        {listened ? <Rating name="note" value={playlistItem.note} size="small" readOnly/> : ""}
                        &nbsp;&nbsp;&nbsp;
                        <span className='text-no-bold font-small'>by <span className='item-user'>{playlistItem.user.name}</span></span>
                        &nbsp;&nbsp;&nbsp;
                        <span className="text-no-bold font-x-small">
                            {playlistItem.listened ? "Listened the " + date?.toLocaleDateString() + " at " + date?.toLocaleTimeString() : "Unlistened"}
                        </span>
                        <a href={playlistItem.album.url} target="_blank" rel="noreferrer"><button className='inline-button'><LaunchIcon fontSize='small'/></button></a>
                    </p>
                    <hr/>
                    <Markdown>{comment}</Markdown>
                    {getAlbumEmbed(playlistItem.id, playlistItem.album, forceUpdate)}
                </div>
                );
        } else {
            return (
                <h1 className='center'>Loading...</h1>
            )
        }
    }

    return (
        <main>
            {getPlaylistItemContent()}
        </main>
    )
}