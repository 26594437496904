import { createContext, useMemo, useState } from 'react';
import { IUserProfile, UserContextType } from '../@types/user';

export const UserContext = createContext<UserContextType|null>(null);

// @ts-ignore
// eslint-disable-next-line react/function-component-definition
const UserProvider: React.FC<React.ReactNode> = ({children}) => {
    let userProfileInStorage: string | null = localStorage.getItem('user');
    let userProfileTemp: IUserProfile | null = null;
    if (userProfileInStorage !== null) {
        userProfileTemp=JSON.parse(atob(userProfileInStorage));
    }
    const [userProfile, setUserProfile] = useState<IUserProfile | null>(userProfileTemp);

    const updateUserProfile = (profile: IUserProfile) => {
        localStorage.setItem('user', btoa(JSON.stringify(profile)))
        setUserProfile(profile);
    }

    const removeUserProfile = () => {
        localStorage.removeItem('user');
        setUserProfile(null);
    }

    const values = useMemo(
        () => ({userProfile, updateUserProfile, removeUserProfile}),
        [userProfile]
    )

    return (
        <UserContext.Provider value={values}>{children}</UserContext.Provider>
    );
}

export default UserProvider;