import React, { useEffect, useState } from 'react';
import { apiGetConfiguration } from '../services/SecurityService';

export default function LegalDocument() {
    const [links, setLinks] = useState<any[]>([]);

    useEffect(() => {
        const getLegal = async () => {
            apiGetConfiguration().then((data) => {
                let linksTemp = [];
                const privacyPolicy = data.get('privacypolicy');
                const termsOfService = data.get('termsofservice');

                if (termsOfService && termsOfService !== "") {
                    linksTemp.push({link:"/terms-of-service", name:"Terms of Service"})
                }
                if (privacyPolicy && privacyPolicy !== "") {
                    linksTemp.push({link:"/privacy-policy", name:"Privacy Policy"});
                }

                setLinks(linksTemp);
            });
        }

        getLegal();
    }, [])

    return (
        <>
            {
                links.length > 0 ?
                <>
                <span className="font-small">
                    {links.map((link) => {
                        return <><a key={link.link} href={link.link}>{link.name}</a> </>
                    })}
                </span><br/></> :
                ""
            }
            
        </>
    )
}