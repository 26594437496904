import React, { useState } from "react";

export default function LocalStoragePage() {
    const [embedConsent, setEmbedConsent] = useState(localStorage.getItem('embed_consent'));
    const [mastodonInstance, setMastodonInstance] = useState(localStorage.getItem('mastodon-domain'));

    const removeEmbedConsent = () => {
        localStorage.removeItem('embed_consent');
        setEmbedConsent("0");
    };
    const removeMastodonInstance = () => {
        localStorage.removeItem('mastodon-domain');
        setMastodonInstance(null);
    };

    return (
        <main>
            <h1>Local storage</h1>
            <p>Here you can reset some of the settings stored into your browser local storage.</p>
            <p>Please note the values used for the user's connection are not displayed here.</p>
            <ul>
                <li>
                    <span className="text-bold">Embed consent (default: no):</span> {embedConsent === "1" ? "yes" : "no"}&nbsp;
                    <button className="link-button" onClick={removeEmbedConsent}>reset</button>
                </li>
                <li>
                    <span className="text-bold">Mastodon instance (default: empty):</span> {mastodonInstance ? mastodonInstance : "empty"}&nbsp;
                    <button className="link-button" onClick={removeMastodonInstance}>reset</button>
                </li>
            </ul>
        </main>
    )
}