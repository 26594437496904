import { IPlaylistItem, IPlaylistItemPaginatedCollection, IPlaylistItemPayload } from "../@types/playlistItem";

const apiUrl = process.env.REACT_APP_API_URL;
const maxItemPerPage = (process.env.REACT_APP_MAX_ITEM_PER_PAGE !== undefined ? process.env.REACT_APP_MAX_ITEM_PER_PAGE : 5);

export const apiGetPlaylistItems = async (filter: string, token: string, page: number, forConnectedUser: boolean = false, forcePageLimit: number = 0): Promise<IPlaylistItemPaginatedCollection | null> => {
    const urlParams = window.location.search.substring(1);
    const apiParams = `${filter}&${urlParams}`;
    const pageLimit = forcePageLimit === 0 ? maxItemPerPage : forcePageLimit;

    let url;
    if (token !== null && token !== "") {
        if (forConnectedUser) {
            url = `${apiUrl}/user-playlist?page=${page}&limit=${pageLimit}${apiParams}`;
        } else {
            url = `${apiUrl}/playlist-items?page=${page}&limit=${pageLimit}${apiParams}`;
        }
    } else {
        url = `${apiUrl}/public/playlist-items?page=${page}&limit=${pageLimit}${apiParams}`;
    }

    const response = await (fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    if (response.status !== 200) {
        return null;
    }

    let jsonResponse = await response.json();

    if (jsonResponse.data.length > 0) {
        return {
            "items": jsonResponse.data,
            "links": jsonResponse.links,
            "meta": jsonResponse.meta,
        }
    } else {
        return {
            "items": jsonResponse.data,
            "links": null,
            "meta": null,
        }
    }
}

export const apiGetOnePlaylistItem = async (id: number, token: string): Promise<IPlaylistItem | null> => {
    let url
    if (token !== null && token !== "") {
        url = apiUrl + '/playlist-items/' + id
    } else {
        url = apiUrl + '/public/playlist-items/' + id
    }
    const response = await (fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }));

    if (response.status === 401 || response.status === 404) {
        return null;
    }

    let jsonResponse = await response.json();
    if (jsonResponse === false) {
        return null;
    }

    return jsonResponse.data
}

export const apiGetPlaylistItemsByAlbumId = async (albumId: number): Promise<IPlaylistItem[] | null> => {
    const response = await fetch(apiUrl + '/public/albums/' + albumId + '/playlist-items', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    
    if (response.status !== 200) {
        return null;
    }

    let jsonResponse = await response.json();
    return jsonResponse.data;
}

export const apiCreatePlaylistItem = async (item: IPlaylistItemPayload, token: string): Promise<IPlaylistItem> => {
    const response = await fetch(apiUrl + '/playlist-items', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })

    const json = await response.json()
    return json.data
}

export const apiUpdatePlaylistItem = async (id: number, item: IPlaylistItemPayload, token: string): Promise<IPlaylistItem> => {
    const response = await fetch(apiUrl + `/playlist-items/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    const json = await response.json()
    return json.data
}

export const apiDeletePlaylistItem = async (id: number, token: string): Promise<Response> => {
    return await fetch(apiUrl + `/playlist-items/${id}`, {
        method: 'DELETE',
        headers: {
            'accept': '*/*',
            'Authorization': `Bearer ${token}`
        }
    })
}